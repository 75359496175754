<template>
    <div class="d-flex flex-column">
        <media-library
            v-if="model"
            v-model="settings.src"
            :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
            label="Аудио файл"
            type="mp3"
            class="my-4"
        />
        <v-alert v-else type="error" class="my-2">
            Возможность использования медиа-библиотеки заблокирована для несохранённой формы.
            Сохраните форму и обновите страницу.
        </v-alert>

        <v-textarea
            v-model="settings.textInputMultilineSettings.placeholder"
            label="Плейсхолдер поля ответа"
            class="mt-4"
            :color="$const.color.primary"
            rows="3"
        ></v-textarea>
        <v-checkbox
            v-model="settings.isControlLock"
            label="Управление записью"
            persistent-hint
            hint="Перемотка, перезапись и пр."
        ></v-checkbox>
        <v-checkbox
            v-model="settings.isModePlayer"
            label="Режим плеера"
            persistent-hint
            hint="В режиме плеера не отображается поле ввода ответа"
        ></v-checkbox>
    </div>
</template>
<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import MediaLibrary from '@/components/inputs/media-library'
import StringHelper from '@/plugins/string'

export default {
    mixins: [interactiveMixin],
    components: { MediaLibrary },
    inject: ['model'],
    data () {
        return {
            settings: {
                src: '',
                isControlLock: false,
                isModePlayer: false,
                textInputMultilineSettings: {
                    placeholder: "Текст диктанта"
                }
            },
            correctAnswer: null
        }
    },
    computed: {
        transliterate () { return StringHelper.transliterate }
    }
}
</script>