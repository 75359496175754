/**
 * Преобразует массив с координатами в объект координат
 * @param {Array} point [x, y]
 * @returns {Object} {x, y}
 */
const transformArrayToObject = (point) => {
  return { x: point[0], y: point[1] }
}
/**
 * Преобразует объект с координатами в массив координат
 * @param {Object} point {x, y}
 * @returns {Array} [x, y]
 */
const transformObjectToArray = (point) => {
  return [ point.x, point.y ]
}
/**
 * Метод определяет координату пересечения двух переданных отрезков
 * @param {Object} point1 Объект координат начальной точки первого отрезка - {x, y}
 * @param {Object} point2 Объект координат конечной точки первого отрезка - {x, y}
 * @param {Object} point3 Объект координат начальной точки второго отрезка - {x, y}
 * @param {Object} point4 Объект координат конечной точки второго отрезка - {x, y}
 * @returns {Object|Boolean} Объект координат, если есть пересечение или `false`, если пересечений нет
 */
const getCrossCoord = (point1, point2, point3, point4) => {
  let n = null,
      dot = []
  if (point2.y - point1.y !== 0) {  // a(y)
    const q = (point2.x - point1.x) / (point1.y - point2.y),
          sn = (point3.x - point4.x) + (point3.y - point4.y) * q, // c(x) + c(y)*q
          fn = (point3.x - point1.x) + (point3.y - point1.y) * q  // b(x) + b(y)*q
    if (!sn) { return false } // Пересечений нет
    n = fn / sn
  }
  else {
    if (!(point3.y - point4.y)) { return false }      // Пересечений нет
    n = (point3.y - point1.y) / (point3.y - point4.y) // c(y)/b(y)
  }
  dot[0] = point3.x + (point4.x - point3.x) * n  // point3.x + (-b(x))*n
  dot[1] = point3.y + (point4.y - point3.y) * n  // point3.y +(-b(y))*n
  return { x: dot[0], y: dot[1] }
}
/**
 * Метод возвращает координаты точки,
 * которая находится на отрезке между двумя другими точками
 * @param {Object} point1 - {x,y} значение координаты точки А
 * @param {Object} point2 - {x,y} значение координаты точки B
 * @param {Number} newLen - Расстояние на котором лежит искомая точка от точки A
 * @returns {Object} {x,y}
 */
const getCoordsForLineWithLength = (point1, point2, newLen) => {
  const fullLen = getLineLengthByCoords(point1, point2),
        k = newLen / fullLen
  if (newLen >= 0) {
    return {
      x: point1.x + (point2.x - point1.x) * k,
      y: point1.y + (point2.y - point1.y) * k
    }
  } else {
    return {
      x: point1.x - (point1.x - point2.x) * k,
      y: point1.y - (point1.y - point2.y) * k
    }
  }
}
/**
 * Возвращает флаг, говорящий находится ли целевая точка на переданном отрезке
 * @param {Object} point Целевая точка
 * @param {Array} segment Массив из двух точек - отрезок
 * @returns {Boolean}
 */
const isPointOnSegment = (point, segment) => {
  if (
      point.x === segment[0].x && point.y === segment[0].y ||
      point.x === segment[1].x && point.y === segment[1].y
    ) { return true; }
  // Разбиваем отрезок на два вектора: от начала отрезка к точке и от начала отрезка к концу отрезка
  const vector1 = {
    x: point.x - segment[0].x,
    y: point.y - segment[0].y
  };
  const vector2 = {
      x: segment[1].x - segment[0].x,
      y: segment[1].y - segment[0].y
  };

  // Вычисляем скалярное произведение векторов
  const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;

  // Вычисляем длины векторов
  const length1 = Math.sqrt(vector1.x * vector1.x + vector1.y * vector1.y);
  const length2 = Math.sqrt(vector2.x * vector2.x + vector2.y * vector2.y);

  // Вычисляем угол между векторами
  const angle = Math.acos(dotProduct / (length1 * length2));

  // Проверяем, находится ли точка на отрезке (с учетом погрешности)
  const epsilon = 0.0001; // Погрешность для сравнения чисел с плавающей запятой
  return (
      angle < epsilon &&
      length1 <= length2 &&
      length1 >= 0 &&
      length1 <= length2
  );
}

/**
 * Метод возвращает длину отрезка
 * @param {Object} point1 - Объект координаты точки А вида {x, y}
 * @param {Object} point2 - Объект координаты точки В вида {x, y}
 * @returns {Number}
 */
const getLineLengthByCoords = (point1, point2) => {
  return Math.sqrt( Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2) )
}

/**
 * Метод возвращает новую координату точки A, в результате поворота на `angle` градусов
 * используя точку B, как центр (опору)
 * @param {Object} point1 - Объект координаты точки А вида {x, y}
 * @param {Object} point2 - Объект координаты точки B вида {x, y}
 * @param {Number} angle - угол поворота точки A от опорной точки в радианах
 * @returns {Number}
 */
const rotatePoint = (point1, point2, angle) => {
  return {
    x: (point1.x - point2.x) * Math.cos(angle) - (point1.y - point2.y) * Math.sin(angle) + point2.x,
    y: (point1.x - point2.x) * Math.sin(angle) + (point1.y - point2.y) * Math.cos(angle) + point2.y
  }
}
/**
 * Метод возвращает координаты точки находящейся посередине между двумя переданными точками
 * @param {Object} point1 - Объект координаты точки А вида {x, y}
 * @param {Object} point2 - Объект координаты точки B вида {x, y}
 * @returns {Object}
 */
const getCenterBetweenPoints = (point1, point2) => {
  const biggerX = point2.x > point1.x ? point2.x : point1.x,
        smallerX = point2.x > point1.x ? point1.x : point2.x,
        biggerY = point2.y > point1.y ? point2.y : point1.y,
        smallerY = point2.y > point1.y ? point1.y : point2.y
  return {
    x: smallerX + Math.abs(biggerX - smallerX)/2,
    y: smallerY + Math.abs(biggerY - smallerY)/2
  }
}

/**
 * Метод возвращает координаты точки находящуейся на расстоянии между точкой А и В
 * в позициии соответствующей процентному percent соотношению длины отрезка АВ
 * @param {Object} point1 Объект координаты точки А вида {x, y}
 * @param {Object} point2 Объект координаты точки B вида {x, y}
 * @param {Number} percent Растояние от точки А до точки В в процентах
 * @returns {Object}
 */
const getPosBetweenPointsByPercent = (point1, point2, percent) => {
  const length = getLineLengthByCoords(point1, point2)
  return getCoordsForLineWithLength(point1, point2, (percent / 100) * length)
}


const coordinatesHelper = {
  isPointOnSegment,
  transformArrayToObject,
  transformObjectToArray,
  getCrossCoord,
  getCoordsForLineWithLength,
  rotatePoint,
  getLineLengthByCoords,
  getCenterBetweenPoints,
  getPosBetweenPointsByPercent
}

export default coordinatesHelper
export {
  isPointOnSegment,
  transformArrayToObject,
  transformObjectToArray,
  getCrossCoord,
  getCoordsForLineWithLength,
  rotatePoint,
  getLineLengthByCoords,
  getCenterBetweenPoints,
  getPosBetweenPointsByPercent
}