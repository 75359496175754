<template>
    <v-card>
        <v-toolbar
            dark
            color="primary"
        >
            <v-toolbar-title>Разметка клеток</v-toolbar-title>

            <v-btn
                elevation="0"
                color="blue"
                class="ml-4"
                :disabled="!hasChanges"
                @click="save"
            >Сохранить</v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                dark
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-container fluid class="pa-4">
            <v-row>
                <v-col cols="12" class="d-flex">
                    <div class="mx-auto">
                        <!-- <p>
                            Определено 4х-угольников: {{ rects.length }}/{{ rectsCount }}<br>
                            Определено единых фигур: {{ figuresCount }}/1
                        </p> -->
                        <svg xmlns="http://www.w3.org/2000/svg"
                            :width="width"
                            :height="height"
                            :viewBox="`0 0 ${width} ${height}`"
                            class="d-block mx-auto"
                        >
                            <g>
                                <template v-for="y in cellsPerHeight">
                                    <template v-for="x in cellsPerWidth">
                                        <rect
                                            :key="`${x - 1}_${y - 1}`"
                                            :x="(x - 1) * (cellSize * scale)"
                                            :y="(y - 1) * (cellSize * scale)"
                                            :width="(cellSize * scale)"
                                            :height="(cellSize * scale)"
                                            class="cell"
                                            :fill="getCellColor(x - 1, y - 1)"
                                            @pointerup.stop="onRectPointerUpHandler({x: x -  1, y: y - 1})"
                                        />
                                    </template>
                                </template>
                            </g>
                        </svg>
                        <!-- <p class="mt-2">Выделенные клетки автоматически делятся на 4х-угольники и выделяются произвольным цветом.</p> -->
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>

const rectColors = [
    'antiquewhite',
    'aquamarine',
    'cadetblue',
    'coral',
    'crimson',
    'yellowgreen',
    'sandybrown',
    'darkmagenta'
]

export default {
    props: {
        value: { type: Boolean, default: false },
        activeCells: { type: Array, default: () => ([]) },
        cellSize: { type: Number, required: true },
        cellsPerWidth: { type: Number, default: 4 },
        cellsPerHeight: { type: Number, default: 4 },
        perimeter: { type: Number, required: true },
        rectsCount: { type: Number, required: true }
    },
    data () {
        return {
            scale: 2,
            width: '100',
            height: '100',
            localActiveCells: [],
            hasChanges: false,
            rects: [],
            figuresCount: 0
        }
    },
    watch: {
        localActiveCells: {
            handler () {
                this.hasChanges = true
            },
            deep: true
        }
    },
    computed: {
        hasProblems () {
            return this.rectsCount !== this.rects.length || this.figuresCount !== 1
        }
    },
    mounted () {
        this.localActiveCells = _.cloneDeep(this.activeCells)
        this.resizeSvg()
    },
    methods: {
        resizeSvg () {
            this.width = this.cellSize * this.cellsPerWidth * this.scale
            this.height = this.cellSize * this.cellsPerHeight * this.scale
        },
        closeDialog () {
            this.$emit('input', false)
        },
        onRectPointerUpHandler ({x, y}) {
            this.toggleCell({x, y})
            // this.rects = this.getRects()
            // this.figuresCount = this.checkWholeFiguresCount()
        },
        toggleCell ({x, y}) {
            const cellIndex = this.findActiveCellIndex({x, y})
            if (cellIndex >= 0) {
                this.localActiveCells.splice(cellIndex, 1)
            } else {
                this.localActiveCells.push({x, y})
            }
        },
        findActiveCellIndex ({x, y}) {
            return this.localActiveCells.findIndex((cell) => cell.x === x && cell.y === y)
        },
        save () {
            this.$emit('save', _.cloneDeep(this.localActiveCells))
            this.hasChanges = false
        },
        getRects () {
            const virtualField = this.getEmptyVirtualField()
            // Активируем ячейки виртуального поля
            this.localActiveCells.forEach((cell) => {
                virtualField[cell.y][cell.x] = true
            })
            const rects = []
            // Проходимся по каждой ячейке
            for (let yIndex = 0; yIndex < virtualField.length; yIndex++) {
                for (let xIndex = 0; xIndex < virtualField[yIndex].length; xIndex++) {
                    if (!virtualField[yIndex][xIndex]) { continue }
                    if (this.cellIncludesInRects(yIndex, xIndex, rects)) { continue }

                    const width = this.getRectWidth(virtualField, yIndex, xIndex)
                    const height = this.getRectHeight(virtualField, yIndex, xIndex, width)

                    rects.push({
                        x: xIndex,
                        y: yIndex,
                        width,
                        height
                    })
                }
            }
            return rects
        },
        getEmptyVirtualField () {
            const virtualField = []
            for (let y = 0; y < this.cellsPerHeight; y++) {
                for (let x = 0; x < this.cellsPerWidth; x++) {
                    if (!virtualField[y]) {
                        virtualField[y] = []
                    }
                    virtualField[y].push(false)
                }
            }
            return virtualField
        },
        getRectWidth (virtualField, yStartIndex, xStartIndex) {
            const row = virtualField[yStartIndex]
            let nextXIndex = xStartIndex
            let rectWidth = 0
            while (row[nextXIndex] === true) {
                rectWidth++
                nextXIndex++
            }
            return rectWidth
        },
        getRectHeight (virtualField, yStartIndex, xStartIndex, width) {
            let nextYIndex = yStartIndex
            let nextXIndex = xStartIndex
            let rectHeight = 0
            
            while (nextYIndex < virtualField.length) {
                const row = virtualField[nextYIndex]
                while (row[nextXIndex] === true) {
                    nextXIndex++
                }
                if (nextXIndex - xStartIndex === width) {
                    nextXIndex = xStartIndex
                    rectHeight++
                    nextYIndex++
                } else {
                    break
                }
            }
            return rectHeight
        },
        getRectIndexByCoords (yIndex, xIndex, rects) {
            if (rects.length < 1) { return -1 }
            for (const key in rects) {
                if (
                    (xIndex >= rects[key].x && xIndex <= (rects[key].x + rects[key].width - 1)) &&
                    (yIndex >= rects[key].y && yIndex <= (rects[key].y + rects[key].height - 1))
                ) return key
            }
            return -1
        },
        cellIncludesInRects (yIndex, xIndex, rects) {
            return this.getRectIndexByCoords(yIndex, xIndex, rects) >= 0
        },
        getCellColor (x, y) {
            const activeCellIndex = this.findActiveCellIndex({x, y})
            if (activeCellIndex < 0) { return 'white' }
            const rectIndex = this.getRectIndexByCoords(y, x, this.rects)
            if (rectIndex < 0) { return 'aqua' }
            if (rectIndex >= this.rects.length) { return 'unset' }
            return rectColors[parseInt(rectIndex)]
        },
        checkWholeFiguresCount () {
            //1: получить любую активную клетку
            const activeCells = _.cloneDeep(this.localActiveCells).map(item => {
                item.checked = false
                return item
            })
            let processingQueue = []
            let figuresCount = 0
            //7: пытаемся получить непроверенные активные клетки
            while (activeCells.findIndex((cell) => cell.checked === false) >= 0) {
                figuresCount++
                //2: поставить ее в очередь на определение активных соседей
                const startingCell = activeCells.find((cell) => !cell.checked)
                processingQueue.push(startingCell)
                //3: запустить обработку очереди
                while (processingQueue.length) {
                    const cell = processingQueue.pop()
                    //6: когда очередь заканчивается помечаем всех их как одну целую фигуру
                    const neighbors = this.checkByNeighbors(activeCells, cell)
                    //4: каждого найденного активного соседа надо поставить в очередь на определение таких же соседей
                    //5: ранее проверенные клетки в очередь не добавляем
                    if (neighbors?.length) {
                        neighbors.forEach((neighbor) => {
                            if (!neighbor.checked) {
                                processingQueue.push(neighbor)
                            }
                        })
                    }
                }
            }
            return figuresCount
            //8: если таких нет, то возвращаем кол-во целых фигур
            //9: если есть - добавляем любую непроверенную активную клетку в очередь и перейти к пункту 3
        },
        checkByNeighbors (activeCells, cell) {
            const leftIndex = activeCells.findIndex((_cell) => _cell.x === cell.x - 1 && _cell.y === cell.y)
            const rightIndex = activeCells.findIndex((_cell) => _cell.x === cell.x + 1 && _cell.y === cell.y)
            const topIndex = activeCells.findIndex((_cell) => _cell.x === cell.x && _cell.y === cell.y - 1)
            const bottomIndex = activeCells.findIndex((_cell) => _cell.x === cell.x && _cell.y === cell.y + 1)
            cell.checked = true
            const neighbors = [];
            [leftIndex, rightIndex, topIndex, bottomIndex].map((i) => i >= 0 && neighbors.push(activeCells[i]))
            return neighbors
        }
    }
}
</script>

<style lang="scss" scoped>
.cell {
    stroke: black;
    &:hover {
        filter: brightness(0.5);
    }
}
</style>