<template>
    <g>
        <line
            :x1="x - (pointsOffset/2) + pointsOffset"
            :y1="y - (pointsOffset/2) + pointsOffset"
            :x2="x + (pointsOffset/2) + pointsOffset"
            :y2="y + (pointsOffset/2) + pointsOffset"
            :stroke="color"
            stroke-width="2"
            class="c-pointer"
        />
        <line
            :x1="x + (pointsOffset/2) + pointsOffset"
            :y1="y - (pointsOffset/2) + pointsOffset"
            :x2="x - (pointsOffset/2) + pointsOffset"
            :y2="y + (pointsOffset/2) + pointsOffset"
            :stroke="color"
            stroke-width="2"
            class="c-pointer"
        />
    </g>
</template>

<script>
export default {
    props: {
        x: { type: [String, Number], required: true },
        y: { type: [String, Number], required: true },
        pointsOffset: { type: [String, Number], required: true },
        color: { type: String, default: 'red' }
    }
}
</script>