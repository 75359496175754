<template>
    <div>
        <div>
            <template v-for="subtask in value.subtasks">
                <subtask
                    :key="subtask.id"
                    :subtask="subtask"
                    class="subtask-wrapper pa-5 mb-5"
                    @input="onSubtaskUpdate"
                    @remove="onRemove"
                />
            </template>
        </div>
        <v-btn
            dark 
            outlined 
            :color="$const.color.primary" 
            small
            @click="addNewSubtask"
        >+ Подзадание</v-btn>

        <!-- Delete selected items confirm dialog -->
        <v-dialog v-model="popup.remove.show" max-width="500px">
            <v-card v-if="popup.remove.show && popup.remove.data">
                <v-card-title class="headline">Вы действительно хотите удалить подзадание {{ popup.remove.data.title }} {{ popup.remove.data.id }}?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark :color="$const.color.primary" @click="closePopup('remove')">Отмена</v-btn>
                    <v-btn outlined @click="removeSubtask(popup.remove.data)">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Subtask from '@/components/task/editor/Subtask.vue'

const getDefaultSubtaskObject = () => ({
                            id: 0,
                            html: '',
                            scoringType: 'scoresSum',
                            inputs: [],
                            groups: [],
                            attachedFile: null
                        })

export default {
    props: {
        value: { type: Object, required: true }
    },
    components: { Subtask },
    data () {
        return {
            popup: {
                remove: {
                    show: false,
                    data: null
                }
            }
        }
    },
    created () {
        if (this.value.subtasks.length === 0) {
            this.addNewSubtask()
        }
    },
    methods: {
        getNewSubtaskId () {
            return (this.value?.subtasks?.reduce((prev, s) => s.id > prev ? s.id : prev, 0) || 0) + 1
        },
        addNewSubtask () {
            const newSubtask = getDefaultSubtaskObject()
            newSubtask.id = this.getNewSubtaskId()
            this.update({
                ...this.value,
                subtasks: [...this.value.subtasks, newSubtask]
            })
        },
        onSubtaskUpdate (subtask) {
            const subtaskList = [...this.value.subtasks]
            const subtaskIndex = subtaskList.findIndex((s) => s.id === subtask.id)
            subtaskList[subtaskIndex] = subtask
            this.update({
                ...this.value,
                subtasks: subtaskList
            })
        }, 
        update (value) {
            this.$emit('input', value)
        },
        onRemove (subtask) {
            this.popup.remove.show = true
            this.popup.remove.data = subtask
        },
        closePopup(type) {
            if (!this.popup[type]) { return }
            this.popup[type].show = false
            this.popup[type].data = null
        },
        removeSubtask (subtask) {
            this.closePopup('remove')
            this.update({
                ...this.value,
                subtasks: this.value.subtasks.filter(s => s.id !== subtask.id)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.subtask-wrapper {
    border-radius: 12px;
    border: 1px solid #d9d7d7;
    font-family: 'Roboto', 'VprInter', sans-serif;
}
</style>