<template>
    <g>
        <rect
            :x="position.x"
            :y="position.y"
            :width="110"
            :height="20"
            fill="white"
            stroke="black"
            stroke-width="1px"
            class="opacity-5 pointer-events-none"
        />
        <text
            :x="position.x + 4"
            :y="position.y + 16"
            style="font-size: 18px;"
            class="pointer-events-none"
            :class="{ 'opacity-5': !active }"
        >{{ limitStringLength(text) || 'Ответ' }}</text>
    </g>
</template>

<script>
import StringHelper from '@/plugins/string.js'

export default {
    props: {
        position: { type: Object, required: true },
        text: { type: String, default: '' },
        active: { type: Boolean, default: false }
    },
    methods: {
        limitStringLength (text) {
            return StringHelper.limitStringLength(text, 8, '...')
        }
    }
}
</script>