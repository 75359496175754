<template>
    <div
        class="polygon-item"
        :class="{ selected }"
    >
        <div class="d-flex">
            <span>Полигон #{{polygon.id + 1}}</span>
            <v-spacer />
            <v-icon class="c-pointer" @click="$emit('click:remove')">mdi-trash-can-outline</v-icon>
        </div>
        <span>Точек: {{polygon.points.length}}</span>
        <div class="d-flex">
            <v-text-field
                :value="polygon.text"
                :type="inputType"
                label="Ответ"
                class="pt-0 mt-2"
                @input="$emit('input:text', $event)"
            ></v-text-field>
            <v-icon
                title="Выбрать позицию для текстового поля"
                class="c-pointer ml-2"
                :class="{'opacity-5 pointer-events-none': inputPositionToolDisabled}"
                style="height: 25px; width: 25px;"
                @click="$emit('click:inputPositionTool')"
            >mdi-target-variant</v-icon>
        </div>
        <v-btn
            x-small
            color="success"
            :disabled="selected"
            @click="$emit('click:select')"
        >{{selected ? 'Выбрано' : 'Выбрать'}}</v-btn>
    </div>
</template>

<script>
export default {
    props: {
        polygon: { type: Object, required: true },
        selected: { type: Boolean, default: false },
        inputPositionToolDisabled: { type: Boolean, default: false },
        inputType: { type: String, default: 'text' }
    }
}
</script>