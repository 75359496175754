export default {
    TextInput: require('@/components/task/editor/interactives/TextInput/Index.vue').default,
    TextInputMultiline: require('@/components/task/editor/interactives/TextInputMultiline.vue').default,
    FormulaInput: require('@/components/task/editor/interactives/FormulaInput.vue').default,
    FamilyTree: require('@/components/task/editor/interactives/FamilyTree.vue').default,
    ClickAndSign: require('@/components/task/editor/interactives/ClickAndSign/Index.vue').default,
    Dropdown: require('@/components/task/editor/interactives/Dropdown.vue').default,
    TextInputFraction: require('@/components/task/editor/interactives/TextInputFraction.vue').default,
    SelectCells: require('@/components/task/editor/interactives/SelectCells/Index.vue').default,
    DrawOnGrid: require('@/components/task/editor/interactives/DrawOnGrid/Index.vue').default,
    ConditionalDisplay: require('@/components/task/editor/interactives/ConditionalDisplay.vue').default,
    AccentInWord: require('@/components/task/editor/interactives/AccentInWord.vue').default,
    AudioRecord: require('@/components/task/editor/interactives/AudioRecord.vue').default,
    AudioDictation: require('@/components/task/editor/interactives/AudioDictation.vue').default,
    ParsingWord: require('@/components/task/editor/interactives/ParsingWord/Index.vue').default,
    ParsingSentence: require('@/components/task/editor/interactives/ParsingSentence/Index.vue').default,
    Calendar: require('@/components/task/editor/interactives/Calendar.vue').default,
    DivideFigure: require('@/components/task/editor/interactives/DivideFigure/Index.vue').default,
    MirroredFigure: require('@/components/task/editor/interactives/MirroredFigure/Index.vue').default,
    FreeDrawing: require('@/components/task/editor/interactives/FreeDrawing.vue').default,
    DragNDrop: require('@/components/task/editor/interactives/DragNDrop.vue').default
}