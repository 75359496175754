<template>
    <div class="w-100 d-flex align-center">
        <template
            v-for="toolsCategory in toolsCategories"
        >
            <span v-if="toolsCategory.title" :key="`${toolsCategory.name}_title`" class="mr-2">{{ toolsCategory.title }}:</span>
            <v-btn
                v-for="tool in tools.filter((tool) => tool.category === toolsCategory.name)"
                :key="`${toolsCategory}_${tool.name}`"
                :title="tool.title"
                icon
                outlined
                class="tool-btn mr-2"
                :class="{ active: activeTool === tool.name }"
                @click="switchTool(tool.name)"
            >
                <v-icon>{{ tool.icon }}</v-icon>
            </v-btn>
            <v-divider :key="`${toolsCategory.name}_divider`" vertical class="mx-5" />
        </template>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'activeTool',
        event: 'change'
    },
    props: {
        activeTool: { type: String, default: null }
    },
    data () {
        return {
            tools: [
                {
                    title: 'Линия',
                    name: 'answerLine',
                    icon: 'mdi-chart-line-variant',
                    category: 'answer'
                },
                {
                    title: 'Ластик',
                    name: 'answerEraser',
                    icon: 'mdi-eraser',
                    category: 'answer'
                }
            ],
            toolsCategories: [
                {
                    title: '', // Ответ
                    name: 'answer'
                }
            ]
        }
    },
    methods: {
        /**
         * Метод переключает значение активного инструмента.
         * Если передано тоже самое значение или `null`,
         * то отключаем активность инструмента
         * @param {String} toolName Название инструмента
         */
        switchTool (toolName) {
            const newValue = this.activeTool === toolName ? null : toolName
            this.$emit('change', newValue)
        }
    }
}
</script>

<style lang="scss" scoped>
.tool-btn {
    &.active {
        background: grey;
        color: white;
    }
}
</style>