<template>
    <div>
        <div class="d-flex flex-row">
            <v-text-field 
                label="Заполнитель поля"
                v-model="settings.textInputSettings.placeholder"
                hint="Текст, который исчезнет после ввода значения" 
                persistent-hint
                type="text"
                class="mr-4"
            ></v-text-field>

            <v-text-field 
                label="Макс. кол-во сим."
                v-model="settings.textInputSettings.maxLength"
                hint="Если не указана, длина ввода не ограничена" 
                persistent-hint
                type="number"
                class="mr-4"
            ></v-text-field>
        </div>

        <div class="d-flex flex-row">
            <v-checkbox
                v-model="settings.textInputSettings.isDigital"
                label="Цифровое поле"
                class="ml-4"
            ></v-checkbox>

            <template v-if="!settings.textInputSettings.isDigital">
                <v-checkbox
                    v-model="settings.textInputSettings.considerCase"
                    label="Учитывать регистр"
                    class="ml-4"
                ></v-checkbox>
                <v-checkbox
                    v-model="settings.textInputSettings.considerWhiteSpace"
                    label="Учитывать пробелы"
                    hint="Убираем пробелы в начале и конце, пробелы в словах схлопываем до одного"
                    persistent-hint
                    class="ml-4"
                ></v-checkbox>
            </template>
            <template v-else>
                <v-checkbox
                    v-model="settings.textInputSettings.considerMinus"
                    label="Учитывать минус"
                    class="ml-4"
                ></v-checkbox>

                <v-checkbox
                    v-model="settings.textInputSettings.considerDot"
                    label="Учитывать точку"
                    hint="Включить, когда точка и запятая имеют разные значения"
                    persistent-hint
                    class="ml-4"
                ></v-checkbox>
            </template>
        </div>


        <div
            v-for="answer in correctAnswer"
            :key="answer.id"
            class="d-flex align-center"
        >
            <v-icon
                v-if="correctAnswer.length > 1"
                @click="answerRemove(answer.id)"
                color="red"
            >mdi-minus-circle-outline</v-icon>
            <v-label>Ответ:</v-label>

            <div class="d-flex flex-column maxW-150">
                <v-text-field 
                    label="Целая часть"
                    v-model="answer.integer"
                    :hint="`Тип данных определен как '${getValueType(answer.integer)}'`"
                    class="mx-4"
                ></v-text-field>
            </div>
            <div class="d-flex flex-column">
                <v-text-field 
                    label="Числитель"
                    v-model="answer.numerator"
                    :hint="`Тип данных определен как '${getValueType(answer.numerator)}'`"
                    class="mr-4 maxW-150"
                ></v-text-field>
                <v-text-field 
                    label="Знаменатель"
                    v-model="answer.denominator"
                    :hint="`Тип данных определен как '${getValueType(answer.denominator)}'`"
                    class="mr-4 maxW-150"
                ></v-text-field>
            </div>
            <v-alert
                v-if="!answer.numerator && !answer.denominator"
                type="warning"
                text
            >Для корректной работы блока как минимум должна быть указана либо целая либо дробная (числитель + знаменатель) часть.</v-alert>
        </div>
        <v-btn @click="answerAdd"><v-icon class="pr-1">mdi-plus-circle-outline</v-icon> Ответ</v-btn>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'

export default {
    mixins: [interactiveMixin],
    data () {
        return {
            lastID: 0,
            correctAnswer: [],
            settings: {
                textInputSettings: {
                    maxLength: 10,
                    considerCase: false,
                    placeholder: '',
                    considerWhiteSpace: false,
                    considerDot: false,
                    considerMinus: false,
                    isDigital: false
                }
            }
        }
    },
    mounted () {
        // Устанавливаем максимальное значение lastID
        this.lastID = this.correctAnswer.reduce((prev, item) => item.id > prev ? item.id : prev, 0)

        if (!this.correctAnswer?.length) {
            this.answerAdd()
        }
    },
    methods: {
        answerRemove (id) {
            this.correctAnswer = this.correctAnswer.filter(item => item.id !== id)
        },
        answerAdd () {
            if (this.correctAnswer.length === 1) {
                const isAgree = confirm('Осторожно! Добавление второго ответа включит режим автоматической фильтрации частей дроби. Это значит, что все спецсимволы будут удалены. Все равно продолжить?')
                if (!isAgree) { return }
            }
            this.correctAnswer.push({
                id: this.lastID++,
                integer: '',
                numerator: '',
                denominator: ''
            })
        },
        getValueType (val) {
            val = String(val)
            // Если диапазон значений
            if (val.indexOf('..') >= 0) {
                return 'Диапазон значений'
            }
            // Если массив 
            else if (val.indexOf(',') >= 0 && val.indexOf('[') >= 0 && val.indexOf(']') >= 0) {
                return 'Массив'
            }
            return 'Строка'
        }
    }
}
</script>