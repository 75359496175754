<template>
    <g>
        <line
            :x1="position.x - 10"
            :y1="position.y - 10"
            :x2="position.x + 10"
            :y2="position.y + 10"
            stroke="orange"
            stroke-width="2"
        />
        <line
            :x1="position.x + 10"
            :y1="position.y - 10"
            :x2="position.x - 10"
            :y2="position.y + 10"
            stroke="orange"
            stroke-width="2"
        />
    </g>
</template>

<script>
export default {
    props: {
        position: { type: Object, required: true }
    }
}
</script>