<template>
    <v-card>
        <v-toolbar
            dark
            color="primary"
        >
            <v-toolbar-title>Разметка задания</v-toolbar-title>

            <v-btn
                elevation="0"
                color="blue"
                class="ml-4"
                :disabled="!hasChanges"
                @click="save"
            >Сохранить</v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                dark
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-container fluid class="pa-4">
            <v-row>
                <v-col cols="12" class="d-flex flex-column">
                    <span class="mr-2">Инструменты:</span>
                    <tool-bar
                        v-model="activeTool"
                        @btnClick:addPointVariant="addPointVariant"
                    />
                    <v-divider class="my-2" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="d-flex flex-wrap align-center">
                    <div
                        v-for="(lp, lpIndex) in localPoints"
                        :key="lpIndex"
                        class="d-flex flex-column mr-10"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                            :width="width"
                            :height="height"
                            :viewBox="`0 0 ${width} ${height}`"
                            class="d-block mx-auto"
                            @pointermove="pointerMoveHandler"
                            @pointerup="pointerUpHandler(lpIndex)"
                            @pointerenter="pointerEnterHandler(lpIndex)"
                        >
                            <!-- Background points -->
                            <g>
                                <template v-for="y in pointsPerHeight">
                                    <template v-for="x in pointsPerWidth">
                                        <circle
                                            :key="`point_${x - 1}_${y - 1}`"
                                            :cx="getCoordByIndex(x - 1)"
                                            :cy="getCoordByIndex(y - 1)"
                                            r="2"
                                        />
                                    </template>
                                </template>
                            </g>
                            <!-- Background horizontal lines -->
                            <g>
                                <template v-for="y in pointsPerHeight">
                                    <line
                                        :key="`h_line_${y - 1}`"
                                        :x1="(pointsOffset / 2) * scale"
                                        :y1="getCoordByIndex(y - 1)"
                                        :x2="(((pointsPerWidth - 1) * (pointsOffset)) + pointsOffset / 2) * scale"
                                        :y2="getCoordByIndex(y - 1)"
                                        stroke="black"
                                    />
                                </template>
                            </g>
                            <!-- Background vertical lines -->
                            <g>
                                <template v-for="x in pointsPerWidth">
                                    <line
                                        :key="`h_line_${x - 1}`"
                                        :x1="getCoordByIndex(x - 1)"
                                        :y1="(pointsOffset / 2) * scale"
                                        :x2="getCoordByIndex(x - 1)"
                                        :y2="(((pointsPerHeight - 1) * (pointsOffset)) + pointsOffset / 2) * scale"
                                        stroke="black"
                                    />
                                </template>
                            </g>
                            <!-- Hint point -->
                            <template v-if="hintPoint && activeVariantIndex === lpIndex">
                                <circle
                                    v-if="['presetPoint', 'presetLine', 'answerLine'].includes(activeTool)"
                                    :cx="getCoordByIndex(hintPoint.x)"
                                    :cy="getCoordByIndex(hintPoint.y)"
                                    r="5"
                                    :fill="hintColor"
                                    class="pointer-events-none"
                                />
                                <mark-icon
                                    v-else-if="activeTool === 'presetMark'"
                                    :x="getCoordByIndex(hintPoint.x)"
                                    :y="getCoordByIndex(hintPoint.y)"
                                    :points-offset="pointsOffset"
                                    :color="hintColor"
                                    class="pointer-events-none"
                                />
                            </template>
                            <!-- Preset points -->
                            <g>
                                <circle
                                    v-for="point in localPresets.points"
                                    :key="`preset_point_${point.x}_${point.y}`"
                                    :cx="getCoordByIndex(point.x)"
                                    :cy="getCoordByIndex(point.y)"
                                    r="5"
                                    fill="blue"
                                    class="c-pointer"
                                    :class="{'pointer-events-none': activeTool !== 'generalEraser'}"
                                    @click.stop="removePresetPoint(point)"
                                />
                            </g>
                            <!-- Preset lines -->
                            <g>
                                <line
                                    v-for="(line, index) in localPresets.lines"
                                    :key="`preset_line_${index}`"
                                    :x1="getCoordByIndex(line[0].x)"
                                    :y1="getCoordByIndex(line[0].y)"
                                    :x2="line[1] ? getCoordByIndex(line[1].x) : getCoordByIndex(hintPoint.x)"
                                    :y2="line[1] ? getCoordByIndex(line[1].y) : getCoordByIndex(hintPoint.y)"
                                    stroke-width="3"
                                    stroke="green"
                                    class="c-pointer"
                                    :class="{'pointer-events-none': activeTool !== 'generalEraser'}"
                                    @click.stop="removePresetLine(index)"
                                />
                            </g>
                            <!-- Preset marks -->
                            <g>
                                <mark-icon
                                    v-for="(mark, index) in localPresets.marks"
                                    :key="`preset_marks_${index}`"
                                    :x="getCoordByIndex(mark.x)"
                                    :y="getCoordByIndex(mark.y)"
                                    :points-offset="pointsOffset"
                                    :class="{'pointer-events-none': activeTool !== 'generalEraser'}"
                                    @click.native.stop="removePresetMark(index)"
                                />
                            </g>
                            <!-- Answer lines -->
                            <g>
                                <line
                                    v-for="(line, index) in lp"
                                    :key="`answer_line_${index}`"
                                    :x1="getCoordByIndex(line[0].x)"
                                    :y1="getCoordByIndex(line[0].y)"
                                    :x2="line[1] ? getCoordByIndex(line[1].x) : getCoordByIndex(hintPoint.x)"
                                    :y2="line[1] ? getCoordByIndex(line[1].y) : getCoordByIndex(hintPoint.y)"
                                    stroke-width="3"
                                    stroke="orange"
                                    class="c-pointer"
                                    :class="{'pointer-events-none': activeTool !== 'generalEraser'}"
                                    @click.stop="removeAnswerLine(lpIndex, index)"
                                />
                            </g>
                        </svg>
                        <div class="d-flex align-center">
                            <span class="mr-2">Вариант ответа {{ lpIndex + 1 }}</span>
                            <v-btn
                                v-if="localPoints.length > 1"
                                title="Удалить вариант"
                                icon
                                outlined
                                class="tool-btn mr-2"
                                @click="removeLocalPoint(lpIndex)"
                            >
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import ToolBar from './ToolBar.vue'
import MarkIcon from './MarkIcon.vue'

const getDefaultPresets = () => ({
                lines: [],
                points: [],
                marks: []
            })

export default {
    components: { ToolBar, MarkIcon },
    props: {
        value: { type: Boolean, default: false },
        points: { type: Array, default: () => ([]) },
        presets: { type: Object, required: true },
        pointsOffset: { type: Number, default: 20 },
        pointsPerWidth: { type: Number, default: 10 },
        pointsPerHeight: { type: Number, default: 10 }
    },
    data () {
        return {
            localPoints: null,
            localPresets: null,
            hasChanges: false,
            scale: 2,
            activeTool: null,
            hintPoint: null,
            lineDrawing: false,
            activeVariantIndex: null
        }
    },
    watch: {
        localPoints: {
            handler () { this.hasChanges = true },
            deep: true
        },
        localPresets: {
            handler () { this.hasChanges = true },
            deep: true
        }
    },
    computed: {
        width () {
            return this.pointsOffset * this.pointsPerWidth * this.scale
        },
        height () {
            return this.pointsOffset * this.pointsPerHeight * this.scale
        },
        hintColor () {
            const tool2color = {
                answerLine: 'rgb(255, 165, 0, .5)',
                presetPoint: 'rgba(0, 0, 255, .5)',
                presetLine: 'rgba(0, 255, 0, .5)',
                presetMark: 'rgba(255, 0, 0, .5)'
            }
            return this.activeTool ? tool2color[this.activeTool] : 'black'
        }
    },
    created () {
        this.localPoints = _.cloneDeep(this.points)
        this.localPresets = Object.keys(this.presets).length ? _.cloneDeep(this.presets) : getDefaultPresets()

        if (!Array.isArray(this.localPoints) || this.localPoints?.length < 1) {
            this.localPoints = [[]]
        }
    },
    methods: {
        /**
         * Хендлер перемещения курсора
         * @param {PointerEvent} event Событие курсора
         */
        pointerMoveHandler (event) {
            this.hintPointMove(event)
        },
        /**
         * Хендлер "отжатия" кнопки курсора
         * @param {Any} payload Любой набор данных
        */
        pointerUpHandler (payload) {
            if (!this.activeTool) { return false }
            const funcPartName = this.activeTool.charAt(0).toUpperCase() + this.activeTool.slice(1)
            this[`add${funcPartName}`]?.({...this.hintPoint}, payload)
        },
        /**
         * Хендлер входа курсора в область варианта ответа
         * @param {Number} lpIndex Индекс группы варианта ответа
        */
        pointerEnterHandler (lpIndex) {
            // Запоминаем индекс последней области в которую зашёл курсор
            this.activeVariantIndex = lpIndex
        },
        /**
         * Метод добавляет ещё один элемент (метку) в массив localPresets.marks
         * @param {Object {x: Number, y: Number}} Координата метки
         */
        addPresetMark ({x, y}) {
            // Если такой маркер уже есть - пропускаем
            if (this.localPresets.marks.findIndex((m) => m.x === x && m.y === y) >= 0) { return false }
            this.localPresets.marks.push({x, y})
        },
        /**
         * Метод удаляет элемент (метку) из массива localPresets.marks по индексу точки
         * @param {Number} index Индекс точки
         */
        removePresetMark (markIndex) {
            this.localPresets.marks.splice(markIndex, 1)
        },
        /**
         * Метод удаляет элемент (точку) из массива localPoints по индексу точки
         * @param {Number} index Индекс точки
         */
        removeLocalPoint (index) {
            this.localPoints.splice(index, 1)
        },
        /**
         * Метод удаляет элемент (линию) из массива localPresets.lines по индексу линии
         * @param {Number} lineIndex Индекс линии
         */
        removePresetLine (lineIndex) {
            this.localPresets.lines.splice(lineIndex, 1)
        },
        /**
         * Метод добавляет ещё один элемент (линию) в массив correctAnswer
         * @param {Object {x: Number, y: Number}} Координата точки. Либо первой в линии, либо завершающей
         * @param {Number} lpIndex Индекс группы
         */
        addAnswerLine ({x, y}, lpIndex) {
            this.lineDrawing = true
            const unfinishedLine = this.localPoints[lpIndex].find((l) => l.length === 1)
            // Finish line
            if (unfinishedLine) {
                unfinishedLine.push({x, y})
                this.lineDrawing = false
                this.localPoints[lpIndex] = this.removeSameLines(this.localPoints[lpIndex])
                this.localPoints[lpIndex] = this.removeZeroLines(this.localPoints[lpIndex])
            } else {
            // Start new line
                this.localPoints[lpIndex].push([{x, y}])
            }
        },
        /**
         * Метод добавляет ещё один элемент (линию) в массив localPresets.lines
         * @param {Object {x: Number, y: Number}} Координата точки. Либо первой в линии, либо завершающей
         */
        addPresetLine ({x, y}) {
            this.lineDrawing = true
            const unfinishedLine = this.localPresets.lines.find((l) => l.length === 1)
            // Finish line
            if (unfinishedLine) {
                unfinishedLine.push({x, y})
                this.lineDrawing = false
                this.localPresets.lines = this.removeSameLines(this.localPresets.lines)
                this.localPresets.lines = this.removeZeroLines(this.localPresets.lines)
            } else {
            // Start new line
                this.localPresets.lines.push([{x, y}])
            }
        },
        /**
         * Метод удаляющий из переданного массива линии, в которых точка начала равна точке конца
         * @param {Array} arr Массив с линиями
         */
        removeZeroLines (arr) {
            return arr.filter((el) => !(el[0].x === el[1].x && el[0].y === el[1].y))
        },
        /**
         * Метод очищает переданный массив от дубликатов линиий, оставляя только уникальные
         * @param {Array} arr Массив с линиями
         */
        removeSameLines (arr) {
            const lines = []
            arr.forEach((line) => {
                const lineIndex = lines.findIndex((l) => (l[0]?.x === line[0]?.x &&
                                                        l[0]?.y === line[0]?.y &&
                                                        l[1]?.x === line[1]?.x &&
                                                        l[1]?.y === line[1]?.y) ||
                                                        (l[0]?.x === line[1]?.x &&
                                                        l[0]?.y === line[1]?.y &&
                                                        l[1]?.x === line[0]?.x &&
                                                        l[1]?.y === line[0]?.y)
                                )
                if (lineIndex < 0) {
                    lines.push(line)
                }
            })
            return lines
        },
        /**
         * Метод удаляет элемент (линию) из массива correctAnswer по индесу группы и индексу линии
         * @param {Number} lpIndex Индекс группы
         * @param {Number} index Индекс линии
         */
        removeAnswerLine(lpIndex, index) {
            this.localPoints[lpIndex].splice(index, 1)
        },
        /**
         * Метод удаляет элемент (координату) из массива localPresets.points
         * @param {Object {x: Number, y: Number}} Координата точки
         */
        removePresetPoint ({x, y}) {
            this.localPresets.points = this.localPresets.points.filter((p) => !(p.x === x && p.y === y))
        },
        /**
         * Метод добавляет ещё один элемент координаты в массив localPresets.points
         * @param {Object {x: Number, y: Number}} Координата точки
         */
        addPresetPoint ({x, y}) {
            // Если такая точка уже есть - пропускаем
            if (this.localPresets.points.findIndex((p) => p.x === x && p.y === y) >= 0) { return false }
            this.localPresets.points.push({ x, y })
        },
        /**
         * Метод обработки перемещения элемента подсказки
         * @param {PointerEvent} event Событие курсора
         */
        hintPointMove (event) {
            if (!this.hintPoint) { this.hintPoint = { x: 0, y: 0 } }
            this.hintPoint = this.activeTool === 'presetMark' ?
                                this.getTopLeftNeighborNodeCoords(event.offsetX, event.offsetY) :
                                this.getNeighborNodeCoords(event.offsetX, event.offsetY)
        },
        /**
         * Метод возвращает индекс ближайшего верхнего левого узла по переданным координатам
         * @param {Number} x X координата в пикселях
         * @param {Number} y Y координата в пикселях
         * @returns {Object {x: Number, y: Number}}
         */
        getTopLeftNeighborNodeCoords (x, y) {
            let xIndex = _.floor(  _.floor( ((x - this.pointsOffset) / this.width) * this.pointsPerWidth ), 1)
            if (xIndex < 0) { xIndex = 0 }
            if (xIndex >= this.pointsPerWidth - 1) { xIndex = this.pointsPerWidth - 2 }

            let yIndex = _.floor(  _.floor( ((y - this.pointsOffset) / this.height) * this.pointsPerHeight ), 1)
            if (yIndex < 0) { yIndex = 0 }
            if (yIndex >= this.pointsPerHeight - 1) { yIndex = this.pointsPerHeight - 2 }

            return { x: xIndex, y: yIndex }
        },
        /**
         * Метод возвращает индекс ближайшего узла по переданным координатам
         * @param {Number} x X координата в пикселях
         * @param {Number} y Y координата в пикселях
         * @returns {Object {x: Number, y: Number}} 
         */
        getNeighborNodeCoords (x, y) {
            let xIndex = _.round(  _.floor( ((x - this.pointsOffset) / (this.width - this.pointsOffset)) * this.pointsPerWidth ), 1)
            if (xIndex < 0) { xIndex = 0 }
            if (xIndex >= this.pointsPerWidth - 1) { xIndex = this.pointsPerWidth - 1 }

            let yIndex = _.round(  _.floor( ((y - this.pointsOffset) / (this.height - this.pointsOffset)) * this.pointsPerHeight ), 1)
            if (yIndex < 0) { yIndex = 0 }
            if (yIndex >= this.pointsPerHeight - 1) { yIndex = this.pointsPerHeight - 1 }

            return { x: xIndex, y: yIndex }
        },
        /**
         * Метод высчитывает координаты одного из значений точки по её индексу
         * @param {Number} value Индекс
         * @returns {Number}
         */
        getCoordByIndex (value) {
            return ((value * this.pointsOffset) + this.pointsOffset / 2) * this.scale
        },
        /**
         * Метод закрывает диалоговое окно
         */
        closeDialog () {
            this.$emit('input', false)
        },
        /**
         * Метод сохранения. Обновляем данные пропсов.
         */
        save () {
            this.$emit('update:points', _.cloneDeep(this.localPoints))
            this.$emit('update:presets', _.cloneDeep(this.localPresets))
            this.hasChanges = false
        },
        /**
         * Метод добавляет ещё один вариант задания (массив ответов)
         */
        addPointVariant () {
            this.localPoints.push([])
        }
    }
}
</script>