<template>
    <div>
        <div class="d-flex flex-row mb-5">
            <v-text-field 
                label="Начальное значение года" 
                v-model="settings.initYear"
                type="number"
                class="maxW-150 mr-2"
            />

            <v-checkbox
                label="Блокировать переключатель лет" 
                v-model="settings.isLockChangeYear"
                type="checkbox"
            />
        </div>

        <v-label>Ответ</v-label>
        <div class="d-flex flex-row">
            <v-text-field 
                label="Год" 
                v-model="correctAnswer.year"
                type="number"
                class="maxW-150 mr-2"
            />

            <v-select
                v-model="correctAnswer.month"
                :items="months"
                label="Месяц"
                class="maxW-150 mr-2"
            />

            <v-text-field 
                label="День"
                v-model="correctAnswer.day"
                type="number"
                class="maxW-150"
            />
        </div>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
const date = new Date()

export default {
    mixins: [interactiveMixin],
    data () {
        return {
            correctAnswer: { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() },
            settings: {
                initYear: date.getFullYear(),
                isLockChangeYear: false
            },
            months: [
                { text: 'Январь', value: 0 },
                { text: 'Февраль', value: 1 },
                { text: 'Март', value: 2 },
                { text: 'Апрель', value: 3 },
                { text: 'Май', value: 4 },
                { text: 'Июнь', value: 5 },
                { text: 'Июль', value: 6 },
                { text: 'Август', value: 7 },
                { text: 'Сентябрь', value: 8 },
                { text: 'Октябрь', value: 9 },
                { text: 'Ноябрь', value: 10 },
                { text: 'Декабрь', value: 11 },
            ]
        }
    }
}
</script>