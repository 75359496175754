<template>
    <div>
        <v-text-field 
            label="Заголовок поля ввода" 
            v-model="settings.textInputSettings.label"
            hint="Не обязательно"
            persistent-hint
        ></v-text-field>

        <v-text-field 
            label="Заполнитель поля"
            v-model="settings.textInputSettings.placeholder"
            hint="Текст, который исчезнет после ввода значения" 
            persistent-hint
            type="text"
            class="mr-4"
        ></v-text-field>

        <v-card class="mt-5">
            <v-card-text>
                <div class="d-flex align-center">
                    <span class="text-h6 text--primary mr-5">{{ correctAnswer.length > 1 ? 'Варианты ответа' : 'Ответ' }}</span>
                </div>
                <v-text-field
                    v-for="(answer, i) in correctAnswer"
                    :key="i"
                    v-model="correctAnswer[i]"
                    :type="settings.isDigital ? 'number' : 'text'"
                >
                    <template v-slot:append-outer>
                        <v-icon v-if="correctAnswer.length > 1" @click="answerRemove(i)">mdi-minus-circle-outline</v-icon>
                    </template>
                </v-text-field>
                <v-icon @click="answerAdd">mdi-plus-circle-outline</v-icon>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'

export default {
    mixins: [interactiveMixin],
    data () {
        return {
            correctAnswer: [],
            settings: {
                textInputSettings: {
                    label: "", //текст заголовка поля ввода
                    placeholder: "Введите выражение",
                }
            }
        }
    },
    mounted () {
        if (this.correctAnswer.length < 1) {
            this.correctAnswer.push('')
        }
    },
    methods: {
        answerAdd () {
            this.correctAnswer.push('')
        },
        answerRemove (index) {
            this.correctAnswer.splice(index, 1)
        }
    }
}
</script>