<template>
    <div>
        <div class="d-flex flex-row">
            <v-text-field
                v-model="settings.label"
                label="Заголовок"
                :color="$const.color.primary"
                class="mr-5"
            ></v-text-field>

            <v-text-field
                v-model="settings.placeholder"
                label="Текст до выбора"
                :color="$const.color.primary"
                class="mr-5"
            ></v-text-field>

            <v-text-field
                v-model="settings.height"
                label="Высота элементов списка (px)"
                type="number"
                :color="$const.color.primary"
            ></v-text-field>
        </div>

        <div class="d-flex flex-row">
            <v-checkbox
                v-model="settings.isSortDisabled"
                label="Использовать заданную сортировку"
                hint="Отобразить список, согласно настройкам (сортировка по алфавиту отключена)"
                persistent-hint
                :color="$const.color.primary"
                class="mr-5 mb-5"
            />
        </div>

        <v-card class="mb-3">
            <v-card-text>
                <p class="text--primary text-h6">Элементы списка</p>
                <div class="d-flex">
                    <div class="mr-5" style="width: 50%">Текст пункта</div>
                    <div class="mr-5">Правильный ответ</div>
                </div>
                <div
                    v-for="(item, i) in settings.items"
                    :key="i"
                    class="d-flex flex-row align-center"
                >
                    <v-text-field
                        v-model="item.text"
                        :color="$const.color.primary"
                        class="mr-5"
                        style="width: 50%; max-width: 50%;"
                    />
                    <v-checkbox
                        v-model="item.isCorrect"
                        :color="$const.color.primary"
                        class="mr-5"
                        @change="() => $nextTick(() => checkForCorrectItems(i))"
                    />
                    <v-icon
                        v-if="settings.items.length > 1"
                        class="c-pointer"
                        style="height: 25px; width: 25px;"
                        @click="itemRemove(i)"
                    >mdi-trash-can-outline</v-icon>
                </div>
                <v-icon @click="itemAdd">mdi-plus-circle-outline</v-icon>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'

export default {
    mixins: [interactiveMixin],
    data () {
        return {
            correctAnswer: null,
            settings: {
                label: '',
                placeholder: '',
                height: 40,
                isSortDisabled: false,
                isRandomize: false,
                items: []
            }
        }
    },
    methods: {
        itemAdd () {
          this.settings.items.push({ text: '', isCorrect: this.settings.items.length === 0 })
        },
        itemRemove (index) {
          this.settings.items.splice(index, 1)
          this.checkForCorrectItems()
        },
        // Метод проверяет есть ли в массиве ответов хотя бы один правильный.
        // Если нет, то устанавливает правильный флаг указанному элементу
        checkForCorrectItems (itemIndex = 0) {
            if (this.settings.items.length === 0) { throw new Error(`Can't check any items as correct couse there are no items at all.`) }
            const correctItemIndex = this.settings.items.findIndex((item) => item.isCorrect)
            if (correctItemIndex < 0) {
                this.settings.items[itemIndex].isCorrect = true
            }
        }
    }
}
</script>