<template>
    <div>
        <div class="d-flex flex-row mt-5">
            <v-text-field 
                label="Заголовок" 
                v-model="settings.label"
                type="text"
                class="mr-5"
            />

            <v-text-field 
                label="Заполнитель" 
                v-model="settings.placeholder"
                type="text"
            />
        </div>

        <p class="text--primary text-h6">Элементы списка</p>
        <v-card class="mb-3">
            <v-card-text>
                <div class="d-flex">
                    <div class="mr-5" style="width: 20%">Текст пункта</div>
                    <div>Зависимые интерактивы</div>
                </div>
                <div
                    v-for="(item, i) in settings.dependencies"
                    :key="i"
                    class="d-flex flex-row align-center"
                >
                    <v-text-field
                        v-model="item.text"
                        :color="$const.color.primary"
                        class="mr-5"
                        style="width: 20%; max-width: 20%;"
                    />
                    <v-select
                        v-model="item.interactiveIds"
                        :items="interactives"
                        multiple
                        chips
                    />
                    <v-icon
                        v-if="settings.dependencies.length > 1"
                        class="c-pointer ml-5"
                        style="height: 25px; width: 25px;"
                        @click="itemRemove(i)"
                    >mdi-trash-can-outline</v-icon>
                </div>
                <v-icon @click="itemAdd">mdi-plus-circle-outline</v-icon>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'

export default {
    mixins: [interactiveMixin],
    inject: ['inputs'],
    data () {
        return {
            correctAnswer: null,
            settings: {
                label: 'Выбранная тема:',
                placeholder: 'Выберите тему из списка',
                dependencies: []
            }
        }
    },
    computed: {
        interactives () {
            return this.inputs
            .filter(input => input.type !== 'conditional-display')
            .map(input => {
                return {
                    value: input.id,
                    text: `id: ${input.id} - ${this.$const.editorBlockTypes[input.title]}`
                }
            })
        }
    },
    methods: {
        itemAdd () {
          this.settings.dependencies.push({ text: '', interactiveIds: [] })
        },
        itemRemove (index) {
          this.settings.dependencies.splice(index, 1)
        }
    }
}
</script>