<script>
export default {
    props: {
        originalCorrectAnswer: { required: true },
        originalSettings: { type: Object, required: true }
    },
    data () {
        return {
            correctAnswer: '',
            settings: {}
        }
    },
    watch: {
        correctAnswer: {
            handler () {
                if (JSON.stringify(this.correctAnswer) === JSON.stringify(this.originalCorrectAnswer)) { return }
                this.update()
            },
            deep: true
        },
        settings: {
            handler () {
                if (JSON.stringify(this.settings) === JSON.stringify(this.originalSettings)) { return }
                this.update()
            },
            deep: true
        }
    },
    created () {
        // Broadcast initial values to local variables
        if (this.originalCorrectAnswer) {
            this.correctAnswer = this.originalCorrectAnswer
        }
        if (this.originalSettings && !Array.isArray(this.originalSettings)) {
            Object.keys(this.originalSettings).forEach((key) => {
                this.settings[key] = this.originalSettings[key]
            })
        }
    },
    methods: {
        /**
         * @returns {{ correctAnswer, settings }}
         */
        beforeUpdate () {
            return {
                correctAnswer: this.correctAnswer,
                settings: this.settings
            }
        },
        update () {
            const { correctAnswer, settings } = this.beforeUpdate()
            this.$emit('update', { correctAnswer: _.cloneDeep(correctAnswer), settings: _.cloneDeep(settings) })
        }
    }
}
</script>