<template>
    <div>
        <v-row>
            <v-col>
                <h5>Строка слова-ответа с данными разбора по составу</h5>

                <v-chip
                    v-for="(letter, letterIndex) in wordAsArray"
                    :key="letterIndex"
                    label
                    class="ma-1"
                    :disabled="!selectedChunkKey"
                    :color="stateChunkKeyForLetterIndex(letterIndex) ? 'primary' : 'grey lighten-4'"
                    :text-color="stateChunkKeyForLetterIndex(letterIndex) ? 'white' : 'black'"
                    @click="toggleChunkKeyForLetterIndex(letterIndex)"
                >
                    {{ letter }}
                </v-chip>
            </v-col>
        </v-row>

        <v-row
            v-for="(chunk, key, index) in chunks"
            :key="'chunk-' + index"
        >
            <v-col v-if="availableTypes.includes(chunk.code)">
                <v-chip
                    :class="{ 'pl-0': selectedChunkKey === key && !!lettersByChunkKey(key) || !!lettersByChunkKey(key) }"
                    :color="selectedChunkKey === key ? 'blue lighten-3' : 'blue lighten-5'"
                    :text-color="selectedChunkKey === key ? 'blue darken-4' : 'blue darken-4'"
                    label
                    @click="selectingChunkKey(key)"
                >
                    <v-chip
                        v-if="!!lettersByChunkKey(key)"
                        label
                        color="primary"
                        text-color="white"
                        class="my-1 mr-1"
                        close
                        @click:close="resetLettersOfChunk(key)"
                    >
                        {{ lettersByChunkKey(key) }}
                    </v-chip>
                    {{ chunk.name }}
                </v-chip>
                <v-icon v-if="chunk.isMultiple" class="ml-1" small @click="chunkAdd(chunk.code)">mdi-plus-circle-outline</v-icon>
                <v-icon v-if="chunk.isRemove" class="ml-1" small @click="chunkRemove(key)">mdi-minus-circle-outline</v-icon>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    props: {
        formula: { type: String, default: '' },
        word: { type: String, default: '' },
        availableTypes: { type: Array, default: () => ['prs', 'krn', 'sff', 'kon', 'osn', 'osl', 'osr'] }
    },
    data () {
        return {
            chunks: {
                prs: { code: 'prs', name: 'Приставка', order: 1, isMultiple: true, startIndex: null, length: null },
                krn: { code: 'krn', name: 'Корень', order: 2, isMultiple: true, startIndex: null, length: null },
                sff: { code: 'sff', name: 'Суффикс', order: 3, isMultiple: true, startIndex: null, length: null },
                kon: { code: 'kon', name: 'Окончание', order: 4, startIndex: null, length: null },
                osn: { code: 'osn', name: 'Основа', order: 5, startIndex: null, length: null },
                osl: { code: 'osl', name: 'Левая часть основы', order: 6, startIndex: null, length: null },
                osr: { code: 'osr', name: 'Правая часть основы', order: 7, startIndex: null, length: null },
            },
            structureMap: {},
            selectedChunkKey: null
        }
    },
    computed: {
        wordAsArray () {
            return this.word.split('').concat([' '])
        }
    },
    watch: {
        word () {
            this.createStructureMap()
        },
        /**
         * Формирование строки formula
         */
        structureMap: {
            handler () {
                this.onStructureMapChanged()
            },
            deep: true
        }
    },
    created () {
        this.init()
    },
    methods: {
        init () {
            this.initChunks()

            this.createStructureMap()

            if (this.formula) {
                Object.keys(this.chunks).map(chunkKey => {
                    Object.keys(this.structureMap).forEach(letterIndex => {
                        const startIndex = +this.chunks[chunkKey].startIndex
                        const length = +this.chunks[chunkKey].length
                        this.structureMap[letterIndex][chunkKey] = +letterIndex >= startIndex && +letterIndex <= startIndex + length - 1
                    })
                })
            }
        },
        onStructureMapChanged () {
            let result = [];
            Object.keys(this.chunks).forEach(chunkKey => {
                this.chunks[chunkKey].startIndex = null
                this.chunks[chunkKey].length = null
            })

            for (const key in this.structureMap) {
                if (Object.hasOwnProperty.call(this.structureMap, key)) {
                    const letterSettings = this.structureMap[key]

                    for (const chunkKey in letterSettings) {
                        if (Object.hasOwnProperty.call(letterSettings, chunkKey)) {

                            if (!letterSettings[chunkKey]) { continue }

                            if (this.chunks[chunkKey].startIndex === null) {
                                this.chunks[chunkKey].startIndex = +key
                            }
                            this.chunks[chunkKey].length = (+key + 1) - this.chunks[chunkKey].startIndex
                        }
                    }
                }
            }
            Object.keys(this.chunks).forEach(chunkKey => {
                const key = chunkKey.substring(0, 3)
                if (this.chunks[chunkKey].startIndex !== null) {
                    result.push(`${key}-${this.chunks[chunkKey].startIndex}-${this.chunks[chunkKey].length}`)
                }
            })
            this.$emit('update:formula', result.join(','));
        },
        createStructureMap () {

            this.structureMap = {}

            for (let letterIndex = 0; letterIndex < (`${this.word} `).length; letterIndex++) {
                Vue.set(this.structureMap, letterIndex, {});

                for (const chunkKey in this.chunks) {
                    Vue.set(this.structureMap[letterIndex], chunkKey, false)
                }
            }
        },
        initChunks () {
            const formulaSettings = this.formula.split(',')
            const chunkKeysUsed = []
            formulaSettings.forEach(string => {

                const [ chunkKey, startIndex, length ] = string.split('-')

                if (!this.availableTypes.includes(chunkKey)) { return }

                if (chunkKeysUsed.includes(chunkKey)) { // if has doubles
                    const newKey = this.chunkAdd(chunkKey)
                    this.chunks[newKey].startIndex = startIndex
                    this.chunks[newKey].length = length
                } else {
                    this.chunks[chunkKey].startIndex = startIndex
                    this.chunks[chunkKey].length = length
                }

                chunkKeysUsed.push(chunkKey)
            })
            this.chunksSorting()
        },
        /**
         * Сортировка выделяемых частей слова в предлагаемом списке
         */
        chunksSorting () {
            this.chunks = Object.fromEntries(
                Object.entries(this.chunks).sort((a, b) => a[1].order < b[1].order ? -1 : 1)
            )
        },
        /**
         * Добавление выделяемой части слова
         */
        chunkAdd (chunkKey) {
            let number = 2;
            const getNewChunkKey = () => chunkKey + number;
            while (this.chunks[getNewChunkKey()]) {
                number++
            }

            for (const letterIndex in this.structureMap) {
                Vue.set(this.structureMap[letterIndex], getNewChunkKey(), false)
            }
            const newChunk = _.cloneDeep(this.chunks[chunkKey]);
            newChunk.isRemove = true
            newChunk.startIndex = null
            newChunk.length = null
            Vue.set(this.chunks, getNewChunkKey(), newChunk);
            this.chunksSorting()
            return getNewChunkKey()
        },
        // Удаление выделяемой части слова
        chunkRemove (chunkKey) {
            if (this.selectedChunkKey === chunkKey) {
                this.selectedChunkKey = null
            }

            this.removeLettersOfChunk(chunkKey);
            Vue.delete(this.chunks, chunkKey);

            this.chunksSorting()
        },
        /**
         * Выбор ключа выделяемой части слова
         */
        selectingChunkKey (chunkKey) {
            this.selectedChunkKey = this.selectedChunkKey === chunkKey ? null : chunkKey
        },
        /**
         * Удаление определённой части слова из набора букв
         */
        removeLettersOfChunk (chunkKey) {
            for (const letterIndex in this.structureMap) {
                Vue.delete(this.structureMap[letterIndex], chunkKey)
            }
        },
        /**
         * Сброс выделенных букв по определённой части слова
         */
        resetLettersOfChunk (chunkKey) {
            for (const letterIndex in this.structureMap) {
                this.structureMap[letterIndex][chunkKey] = false
            }
        },
        /**
         * Получение букв по выделяемой части слова
         */
        lettersByChunkKey (chunkKey) {
            let letters = '';

            for (const letterIndex in this.structureMap) {
                if (this.structureMap[letterIndex][chunkKey]) {
                    letters += this.wordAsArray[letterIndex]
                }
            }
            return letters
        },
        /**
         * Состояние выделяемой части слова для буквы по её индексу
         */
        stateChunkKeyForLetterIndex (letterIndex, chunkKey = this.selectedChunkKey) {
            return chunkKey && this.structureMap[letterIndex][chunkKey]
        },
        /**
         * Переключение состояния выделяемой части слова для буквы по её индексу
         */
        toggleChunkKeyForLetterIndex (letterIndex, chunkKey = this.selectedChunkKey) {
            if (!chunkKey) { return }
            this.structureMap[letterIndex][chunkKey] = !this.stateChunkKeyForLetterIndex(letterIndex, chunkKey)
        }
    }
}
</script>