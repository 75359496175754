<template>
    <g>
        <rect
            :x="x"
            :y="y"
            :width="rectWidth"
            :height="pointsOffset * 1.5"
            :stroke="color"
            stroke-width="2"
            :fill="background"
            class="c-pointer"
        />
        <text
            v-if="text"
            ref="text"
            :x="x + textOffset"
            :y="y + pointsOffset"
            class="c-pointer"
        >{{ text }}</text>
    </g>
</template>

<script>
export default {
    props: {
        x: { type: [String, Number], required: true },
        y: { type: [String, Number], required: true },
        pointsOffset: { type: [String, Number], required: true },
        color: { type: String, default: 'red' },
        background: { type: String, default: 'transparent' },
        text: { type: String, default: 'Лейбл' }
    },
    data () {
        return {
            rectWidth: 0,
            textOffset: 5
        }
    },
    watch: {
        text () {
            this.$nextTick(this.calcRectWidth)
        }
    },
    mounted () {
        this.$nextTick(this.calcRectWidth)
    },
    methods: {
        calcRectWidth () {
            this.rectWidth = (this.$refs.text?.getBoundingClientRect?.()?.width || 0) + this.textOffset * 2
        }
    }
}
</script>