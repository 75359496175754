<template>
  <div>
        <v-label>Настройки фигуры</v-label>
        <div class="d-flex">
            <div class="maxW-200 mr-5">
                <v-text-field 
                    label="Размер фигуры" 
                    v-model="settings.figureSize"
                    hint="Можно указывать значение в пикселях и в процентах. При этом обязательно приписывать единицу измеерения (% или px)"
                    persistent-hint
                    @input="onMeasuresSettingsChange"
                ></v-text-field>
            </div>
            <div class="maxW-200 mr-5">
                <v-select
                    v-model="settings.comparisonType"
                    :items="availableComparisonTypes"
                    label="Тип проверки"
                    class="maxW-200 mr-5"
                    @change="onMeasuresSettingsChange"
                />
            </div>

            <div class="maxW-200 mr-5">
                <v-select
                    v-model="settings.figureType"
                    :items="figureTypes"
                    label="Тип фигуры"
                    class="maxW-200"
                    @change="onMeasuresSettingsChange"
                />
            </div>
        </div>
        
        <div
            :key="`${settings.figureSize}_${settings.figureType}_${settings.comparisonType}`"
            class="d-flex flex-column mt-5"
        >
            <label>Разделить фигуру для демонстрации ответа</label>
          
            <div class="mt-5">
                <v-dialog
                    :value="dialogVisible"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                    <template v-slot:activator="{}">
                        <div class="d-flex align-center flex-wrap">
                            <div
                                v-for="(answer, index) in correctAnswer"
                                :key="index"
                                class="mr-5 mb-5"
                            >
                                <v-btn
                                    @click="selectAnswer(index)"
                                >Вариант {{ index + 1 }}</v-btn>
                                <v-icon class="ml-1" @click="removeAnswer(index)">mdi-trash-can</v-icon>
                            </div>
                            <v-btn class="mr-5 mb-5" @click="addVariant">+ Вариант</v-btn>
                        </div>
                        
                    </template>
                    <selector
                        v-if="dialogVisible"
                        :key="selectedIndex"
                        :lines.sync="correctAnswer[selectedIndex]"
                        :size="settings.figureSize"
                        :type="settings.figureType"
                        @close="closeSelector"
                    />
                </v-dialog>
            </div>
            <v-label>Укажите как может выглядеть правильный ответ (обязательно)</v-label>
        </div>
  </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import Selector from './Selector.vue'

export default {
    mixins: [interactiveMixin],
    components: { Selector },
    data () {
        return {
            correctAnswer: [[]],
            selectedIndex: null,
            updateIndex: 0,
            settings: {
                figureSize: 95, // %
                figureType: 'circle',
                comparisonType: '4f'
            }
        }
    },
    computed: {
        sizeLabel () {
            const labels = {
                circle: 'Радиус круга',
                square: 'Сторона квадрата'
            }
            return labels[this.settings.figureType] || 'Размер'
        },
        figureTypes () {
            return [
                { value: 'circle', text: 'Круг' },
                { value: 'square', text: 'Квадрат' }
            ]
        },
        availableComparisonTypes () {
            const types = {
                circle: [{text: '4 части', value: '4f'}, {text: '6 частей', value: '6f'}],
                square: [{text: '3 треугольника и пятиугольник', value: '3t5'}, {text: 'Треугольник и 3 четырехугольника', value: 't34'}]
            }
            return types[this.settings.figureType]
        },
        dialogVisible () {
            return typeof this.selectedIndex === 'number'
        }
    },
    methods: {
        onMeasuresSettingsChange () {
            // Обнуляем ответ, потому настройки позиций придется пересчитывать
            this.correctAnswer = [[]]
        },
        addVariant () {
            this.updateIndex++
            this.correctAnswer.push([])
        },
        closeSelector () {
            this.selectedIndex = null
        },
        selectAnswer (index) {
            this.selectedIndex = index
        },
        removeAnswer (index) {
            this.correctAnswer.splice(index, 1)
        }
    }
}
</script>