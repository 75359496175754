<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model="$v.form.year.$model"
            :error-messages="getErrors('form.year')"
            label="Календарный год" 
            name="year" 
            type="number" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-select
          v-model="$v.form.subject.$model"
          :error-messages="getErrors('form.subject')"
          required
          :items="subjects"
          label="Предмет"
        ></v-select>

        <v-select
          v-model="$v.form.grade.$model"
          :error-messages="getErrors('form.grade')"
          required
          :items="grades"
          label="Класс"
        ></v-select>

        <!-- <v-text-field 
            v-model="form.theme"
            :error-messages="getErrors('form.theme')"
            label="Тема" 
            name="themes" 
            type="text" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field> -->

        <v-autocomplete
            v-model="form.themes"
            label="Темы"
            :items="themes"
            :disabled="themes.length === 0"
            :color="$const.color.primary" 
            multiple
            small-chips
            deletable-chips
        ></v-autocomplete>

        <v-text-field 
            v-model="$v.form.option.$model"
            :error-messages="getErrors('form.option')"
            label="Вариант" 
            name="option" 
            type="number" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <!-- <v-select
            v-model="form.is_final"
            :error-messages="getErrors('form.is_final')"
            label="Итоговая работа"
            :items="[{text: 'Нет', value: false}, {text: 'Да', value: true}]"
            name="is_final"
            :color="$const.color.primary" 
        ></v-select> -->

        <v-text-field 
            v-model="$v.form.task.$model"
            :error-messages="getErrors('form.task')"
            label="Номер задания в варианте" 
            name="task" 
            type="number" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-divider class="my-4" />

        <editor v-model="form.data" />

        <v-divider class="my-4" />

        <keyboard-settigns v-model="form.data.keyboard" />

        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        >
            <v-btn
                v-if="model"
                dark
                outlined
                :color="$const.color.primary"
                small
                target="_blank"
                :href="`/task/preview/${model.id}`"
            >
                <v-icon dark class="mr-2">mdi-eye</v-icon>
                Превью
            </v-btn>
        </action-buttons>
    </v-form>
</template>
<script>

import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Editor from '@/components/task/editor/Index.vue'
import KeyboardSettigns from '@/components/task/editor/KeyboardSettings.vue'
import ActionButtons from '@/components/crud/ActionButtons.vue'

const getDefaultDataObject = () => ({
    subtasks: [],
    keyboard: {}
})
const curYear = (new Date()).getFullYear()

export default {
    components: { Editor, KeyboardSettigns, ActionButtons },
    props: {
        model: { type: Object }
    },
    provide () {
        return {
            model: this.model
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            storeModule: 'task',
            themes: [],
            form: {
                year: curYear,
                subject: '',
                grade: 1,
                option: '',
                task: '',
                themes: [],
                sorting: '1',
                name: 'vpr_template_z',
                data: null,
                raw_data: null,
                // is_final: false
            }
        };
    },
    computed: {
        ...mapState('user', ['roles']),
        ...mapState('task', ['subjects']),
        ...mapGetters('task', ['grades'])
    },
    created () {
        if (!this.form.data) {
            this.form.data = getDefaultDataObject()
        } else if (typeof this.form.data === 'string') {
            this.form.data = JSON.parse(this.form.data)
        }
        if (this.form.themesOfTask?.length) {
            this.form.themes = _.cloneDeep(this.form.themesOfTask)
        }
        this.$store.commit('task/setInitialTaskData', this.form.data)
        this.fetchThemes()
    },
    validations() {
        return {
            form: {
                year: { required },
                subject: { required },
                grade: { required },
                option: { required },
                task: { required }
            }
        }
    },
    methods: {
        async fetchThemes () {
            const { success, data, error } = await this.$store.dispatch('task/fetchThemes')
            if (!success) {
                console.error(error)
                return false;
            }
            this.themes = data.items?.map(item => item.name)
        },
        prepareForm (form) {
            form.data = JSON.stringify(form.data)
            if (this.isCreate) {
                form.raw_data = form.data
            }
            return form
        },
        onValidationFailed () {
            window.scrollTo(0, 0)
        }
    }
}
</script>