<template>
    <div class="d-flex flex-column">
        <v-checkbox
            v-model="localSettings.active"
            label="Использовать вирт. клавиатуру"
        ></v-checkbox>

        <div v-if="localSettings.active" class="d-flex flex-column">

            <h3 class="mb-5">Настройки виртуальной клавиатуры</h3>

            <h4>Используемые листы</h4>
            <v-select
                v-model="localSettings.addLists"
                :items="keyboardLists"
                multiple
                chips
                class="mb-5"
            />

            <h4 class="mb-1">Доп символы на новом листе</h4>
            <v-card
                v-for="(row, ri) in localSettings.addSymbols"
                :key="ri"
                class="d-flex align-center flex-wrap w-100 pa-2 mb-2"
            >
                <v-text-field
                    v-for="(symbol, si) in row"
                    :key="si"
                    v-model="row[si]"
                    :color="$const.color.primary"
                    style="width: calc(100% / 12); max-width: calc(100% / 12)"
                    class="mr-5"
                    append-outer-icon="mdi-trash-can"
                    @click:append-outer="removeSymbol(ri, si)"
                />
                <div class="d-flex">
                    <v-btn class="mr-5" @click="addSym(ri)">+ Символ</v-btn>
                    <v-btn @click="removeRow(ri)">Удалить строку</v-btn>
                </div>
            </v-card>
            <div>
                <v-btn v-if="localSettings.addSymbols.length < 3" @click="addRow">+ Строка</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
const getDefaultKeyboardSettings = () => ({
    active: false,
    addSymbols: [],
    addLists: []
})
export default {
    model: {
        prop: 'settings',
        event: 'change'
    },
    props: {
        settings: { type: Object, default: () => ({}) }
    },
    watch: {
        localSettings: {
            handler () {
                if (JSON.stringify(this.localSettings) === JSON.stringify(this.settings)) { return }
                this.$emit('change', this.localSettings)
            },
            deep: true
        }
    },
    data () {
        return {
            keyboardLists: [],
            localSettings: null
        }
    },
    created () {
        this.localSettings = { ...getDefaultKeyboardSettings(), ...this.settings };

        // Default values
        if (!this.localSettings.addLists.length)
            this.localSettings.addLists = ["Кириллическая клавиатура", "Цифровая клавиатура", "Латинская клавиатура", "Греческая клавиатура"];

        this.fetchKeyboardLists();
    },
    methods: {
        async fetchKeyboardLists () {
            try {
                const { success, data, error } = await this.$store.dispatch('keyboard_list/list', { fields: 'title' });
                
                if (!success) {
                    console.error(error);
                    return false;
                }
                this.keyboardLists = data?.items?.map(item => item.title) || [];
            } catch (e) {
                console.error(e);
            }
        },
        addSym (rowI) {
            this.localSettings.addSymbols[rowI].push('A')
        },
        addRow () {
            this.localSettings.addSymbols.push(['A'])
        },
        removeSymbol (rowI, symI) {
            this.localSettings.addSymbols[rowI].splice(symI, 1)
        },
        removeRow (rowI) {
            this.localSettings.addSymbols.splice(rowI, 1)
        }
    }
}
</script>