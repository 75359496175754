<template>
    <div>
        <v-row>
            <v-col class="d-flex flex-column">

                <v-autocomplete
                    v-model="settings.typeMarkers"
                    :items="availableTypeMarkers"
                    label="Доступные маркеры"
                    chips
                    deletable-chips
                    multiple
                    small-chips
                    @change="onInitialStringChanged"
                ></v-autocomplete>


                <v-text-field
                    v-model="initialString"
                    label="Исходное слово/предложение"
                    hint="Введите слово для разбора или целое предложение"
                    persistent-hint
                    @keyup="onInitialStringChanged"
                />
                <h5 class="mt-5 mb-1">Выберите слово для настройки его разбора</h5>
                <div class="d-flex">
                    <v-chip
                        v-for="(word, index) in cleanWords"
                        :key="index"
                        :color="correctAnswer[selectedVariant].index === index ? 'blue lighten-3' : 'blue lighten-5'"
                        :text-color="correctAnswer[selectedVariant].index === index ? 'blue darken-4' : 'blue darken-4'"
                        label
                        class="mr-2"
                        @click="toggleWord(index)"
                    >
                        {{ word }}
                    </v-chip>
                </div>
            </v-col>
        </v-row>

        <v-tabs
          v-model="selectedVariant"
          class="my-4"
        >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
                v-for="(variant, index) in correctAnswer"
                :key="index"
            >
            Вариант {{ index + 1 }}
            </v-tab>
            <v-btn icon class="ml-1 mt-2" @click="addNewVariant">
                <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
        </v-tabs>

        <v-card class="my-2 pa-2">
            <word-markuper
                v-if="correctAnswer[selectedVariant].index !== null"
                :key="`${selectedVariant}_${correctAnswer[selectedVariant].index}`"
                :available-types="settings.typeMarkers"
                :word="correctAnswer[selectedVariant].word"
                :formula="correctAnswer[selectedVariant].parsing"
                @update:formula="onFormulaUpdate"
            />
            <p v-else class="text--secondary my-5">Выберите слово для настройки его разбора</p>
        </v-card>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import WordMarkuper from './WordMarkuper.vue'

export default {
    mixins: [interactiveMixin],
    components: { WordMarkuper },
    data () {
        return {
            initialString: '',
            selectedWordIndex: null,
            selectedVariant: 0,
            cleanWords: [], // Список слов из предложения без спецсимволов
            availableTypeMarkers: [
                { text: 'Приставка', value: 'prs' },
                { text: 'Корень', value: 'krn' },
                { text: 'Суффикс', value: 'sff' },
                { text: 'Окончание', value: 'kon' },
                { text: 'Основа', value: 'osn' },
                { text: 'Левая часть основы', value: 'osl' },
                { text: 'Правая часть основы', value: 'osr' }
            ],
            settings: {
                sentence: ['сильный'],
                typeMarkers: ['prs', 'krn', 'sff', 'kon', 'osn', 'osl', 'osr']
            },
            correctAnswer: []
        }
    },
    created () {
        if (this.settings.sentence?.length) {
            this.initialString = this.settings.sentence.join(' ')
            this.onInitialStringChanged()
        }
        if (!this.originalCorrectAnswer?.length) {
            this.correctAnswer = [this.getNewVariant()]
        }
    },
    methods: {
        onInitialStringChanged () {
            this.settings.sentence = this.initialString.split(' ')
            this.cleanWords = this.settings.sentence.map(word => this.clearWordFromExcessSymbols(word)).filter(w => !!w)
        },
        /**
         * Метод очистки слова от спецсимволов
         * @returns {String}
         */
        clearWordFromExcessSymbols (word) {
            const regex = /[,.!?\-()–]/gim
            return word.replaceAll(regex, '').trim()
        },
        toggleWord (index) {
            this.correctAnswer[this.selectedVariant].index = index
            this.correctAnswer[this.selectedVariant].word = this.cleanWords[index]
            this.correctAnswer[this.selectedVariant].parsing = ''
        },
        onFormulaUpdate (value) {
            this.correctAnswer[this.selectedVariant].parsing = value
            this.update()
        },
        addNewVariant () {
            this.correctAnswer.push(this.getNewVariant())
        },
        getNewVariant () {
            return { index: null, parsing: '', word: null }
        }
    }
}
</script>