<template>
    <v-card>
        <v-toolbar
            dark
            color="primary"
        >
            <v-toolbar-title>Разметка изображения</v-toolbar-title>

            <v-btn
                elevation="0"
                color="blue"
                class="ml-4"
                :disabled="!canSave"
                @click="save"
            >Сохранить</v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                dark
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-container fluid class="pa-4">
            <v-row>
                <v-col cols="12" class="d-flex">
                    <div class="d-flex flex-column justify-start" style="min-width: 210px">
                        <v-btn @click="polygonAdd">+ Полигон</v-btn>

                        <polygon-u-i-item
                            v-for="polygon in localPolygons"
                            :key="polygon.id"
                            :polygon="polygon"
                            :selected="selectedPolygon && selectedPolygon.id === polygon.id"
                            :input-position-tool-disabled="!selectedPolygon || (selectedPolygon && (selectedPolygon.id !== polygon.id)) || ['input-position', 'click-position'].includes(tool)"
                            :input-type="inputType"
                            class="mt-5"
                            @click:remove="polygonRemove(polygon.id)"
                            @click:select="polygonSelect(polygon)"
                            @click:inputPositionTool="setTool('click-position')"
                            @input:text="(payload) => polygon.text = payload"
                        />
                    </div>
                    <v-divider vertical class="mx-5" />
                    <div style="overflow: auto;">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            :width="width"
                            :height="height"
                            :viewBox="`0 0 ${width} ${height}`"
                        >
                            <image
                                ref="mainImage"
                                :href="imageHref"
                                x="0"
                                y="0"
                                :width="width"
                                :height="height"
                            />
                            <rect
                                x="0"
                                y="0"
                                :width="width"
                                :height="height"
                                fill="rgba(255, 255, 255, .5)"
                                @pointerup.stop="canvasPointerUpHandler"
                                @pointerdown.stop="canvasPointerDownHandler"
                                @pointermove.stop="canvasPointerMoveHandler"
                                @pointerleave.stop="canvasPointerLeaveHandler"
                            />
                            <!-- Inactive polygons -->
                            <g>
                                <polygon
                                    v-for="polygon in localPolygons"
                                    :key="polygon.id"
                                    :points="genPointsSequense(polygon.points)"
                                    fill="none"
                                    stroke="red"
                                    class="opacity-3 pointer-events-none"
                                />
                            </g>
                            <g v-if="selectedPolygon">
                                <!-- Hint for polygon-drawer tool -->
                                <template v-if="hintPoint && tool === 'polygon-drawer'">
                                    <circle
                                        :cx="hintPoint.x"
                                        :cy="hintPoint.y"
                                        :r="pointRadius"
                                        fill="red"
                                        class="opacity-5 pointer-events-none"
                                    />
                                    <polygon
                                        :points="genPointsSequense([...selectedPolygon.points, hintPoint])"
                                        fill="none"
                                        stroke="red"
                                        stroke-dasharray="4 2"
                                        class="opacity-5 pointer-events-none"
                                    />
                                </template>
                                <!-- END - Hint for polygon-drawer tool -->
                                <!-- Hint for input-position tool -->
                                <cross-icon
                                    v-if="hintPoint && tool === 'click-position'"
                                    :position="hintPoint"
                                    class="opacity-5 pointer-events-none"
                                />
                                <line
                                    v-if="hintPoint && tool === 'input-position' && selectedPolygon.clickPosition"
                                    :x1="hintPoint.x"
                                    :y1="hintPoint.y"
                                    :x2="selectedPolygon.clickPosition.x"
                                    :y2="selectedPolygon.clickPosition.y"
                                    stroke-width="1"
                                    class="opacity-5 pointer-events-none"
                                    stroke="orange"
                                />
                                <input-rect
                                    v-if="hintPoint && tool === 'input-position'"
                                    :text="selectedPolygon.text"
                                    :position="hintPoint"
                                />
                                <!-- END - Hint for input-position tool -->

                                <line
                                    v-if="selectedPolygon && selectedPolygon.clickPosition && selectedPolygon.inputPosition"
                                    :x1="selectedPolygon.inputPosition.x"
                                    :y1="selectedPolygon.inputPosition.y"
                                    :x2="selectedPolygon.clickPosition.x"
                                    :y2="selectedPolygon.clickPosition.y"
                                    stroke-width="1"
                                    class="pointer-events-none"
                                    stroke="orange"
                                />
                                
                                <input-rect
                                    v-if="selectedPolygon && selectedPolygon.inputPosition"
                                    :text="selectedPolygon.text"
                                    :position="selectedPolygon.inputPosition"
                                    class="pointer-events-none"
                                />

                                <cross-icon
                                    v-if="selectedPolygon && selectedPolygon.clickPosition"
                                    :position="selectedPolygon.clickPosition"
                                    class="pointer-events-none"
                                />
                                    
                                <polygon
                                    :points="genPointsSequense(selectedPolygon.points)"
                                    fill="none"
                                    stroke="red"
                                    :class="{'pointer-events-none': draggablePoint}"
                                />
                                <circle
                                    v-for="point in selectedPolygon.points"
                                    :key="`${point.x}_${point.y}`"
                                    :cx="point.x"
                                    :cy="point.y"
                                    :r="pointRadius"
                                    fill="red"
                                    :class="{'pointer-events-none': draggablePoint}"
                                />
                                <circle
                                    v-for="point in selectedPolygon.points"
                                    :key="`interaction_point_area_${point.x}_${point.y}`"
                                    :cx="point.x"
                                    :cy="point.y"
                                    :r="pointRadius * 2"
                                    fill="transparent"
                                    class="c-pointer"
                                    :class="{'pointer-events-none': draggablePoint}"
                                    @pointerdown.stop="pointDragStart($event, point)"
                                    @pointerenter.stop="hintPointHide"
                                />
                            </g>
                        </svg>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import PolygonUIItem from '@/components/task/editor/interactives/ClickAndSign/PolygonUIItem.vue'
import InputRect from '@/components/task/editor/interactives/ClickAndSign/InputRect.vue'
import CrossIcon from './CrossIcon.vue'

export default {
    components: { PolygonUIItem, InputRect, CrossIcon },
    props: {
        value: { type: Boolean, default: false },
        polygons: { type: Array, default: () => ([]) },
        image: { type: String, required: true },
        inputType: { type: String, default: 'text' }
    },
    data () {
        return {
            width: '100',
            height: '100',
            pointRadius: 4,
            localPolygons: [],
            selectedPolygon: null,
            hintPoint: null,
            dragOffset: null,
            draggablePoint: null,
            tool: null,
            canSave: false
        }
    },
    watch: {
        localPolygons: {
            handler () { this.canSave = true },
            deep: true
        }
    },
    computed: {
        origin () {
            return window.location.origin
        },
        imageHref () {
            return `${this.origin}/${this.image}`
        }
    },
    mounted () {
        this.localPolygons = _.cloneDeep(this.polygons)
        this.resizeImage()
    },
    methods: {
        resizeImage () {
            const node = new Image()
            node.onload = () => {
                if (node.naturalWidth && node.naturalHeight) {
                    this.width = node.naturalWidth
                    this.height = node.naturalHeight
                }
            }
            node.src = this.imageHref
        },
        closeDialog () {
            this.$emit('input', false)
        },
        canvasPointerUpHandler (e) {
            if (this.tool === 'click-position') {
                this.setTool('input-position')
                this.selectedPolygon.clickPosition = { x: e.offsetX , y: e.offsetY }
            } else if (this.tool === 'input-position') {
                this.setTool('polygon-drawer')
                this.selectedPolygon.inputPosition = { x: e.offsetX , y: e.offsetY }
            } else if (this.tool === 'polygon-drawer') {
                this.draggablePoint ? this.pointDragEnd() : this.pointCreate(e.offsetX, e.offsetY)
            }
        },
        canvasPointerDownHandler () {
            this.pointDragEnd()
        },
        canvasPointerMoveHandler (e) {
            if (!this.selectedPolygon) { return }

            if (this.draggablePoint) {
                this.pointDragHandler(e)
            } else {
                this.hintPointMove(e)
            }
        },
        canvasPointerLeaveHandler () {
            this.hintPointHide()
        },
        hintPointMove (e) {
            if (!this.hintPoint) {
                this.hintPoint = { x: 0, y: 0 }
            }
            this.hintPoint.x = e.offsetX
            this.hintPoint.y = e.offsetY
        },
        hintPointHide () {
            this.hintPoint = null
        },
        pointDragStart (e, point) {
            this.dragOffset = { x: e.offsetX - point.x, y: e.offsetY - point.y }
            this.draggablePoint = point
        },
        pointDragHandler (e) {
            if (!this.draggablePoint) { return }
            this.draggablePoint.x = e.offsetX - this.dragOffset.x
            this.draggablePoint.y = e.offsetY - this.dragOffset.y
        },
        pointDragEnd () {
            this.dragOffset = null
            this.draggablePoint = null
        },
        pointCreate (x, y) {
            if (!this.selectedPolygon) { return }
            this.selectedPolygon.points.push({x, y})
        },
        // Search for free id number
        polygonGetNewId () {
            const usedIds = this.localPolygons.map(p => p.id)
            let n = -1
            let posIndex = null
            do {
                n++
                posIndex = usedIds.findIndex((id) => id === n)
            } while (posIndex >= 0)
            return n
        },
        polygonAdd () {
            const newPolygon = {
                id: this.polygonGetNewId(),
                points: [],
                text: '',
                inputPosition: null,
                clickPosition: null
            }
            this.localPolygons.push(newPolygon)
            this.polygonSelect(newPolygon)
        },
        polygonSelect (polygon) {
            this.setTool('polygon-drawer')
            this.selectedPolygon = polygon
        },
        genPointsSequense (points) {
            return points.map(point => `${point.x},${point.y}`).join(' ')
        },
        polygonRemove (id) {
            if (this.selectedPolygon?.id === id) {
                this.selectedPolygon = null
            }
            this.localPolygons = this.localPolygons.filter(p => p.id !== id)
            this.setTool(null)
        },
        setTool (toolName) {
            this.pointDragEnd()
            this.tool = toolName
        },
        save () {
            this.$emit('save', _.cloneDeep(this.localPolygons))
            this.canSave = false
        }
    }
}
</script>

<style lang="scss" scoped>
.polygon-item {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 4px;
    &:hover, &.selected {
        border: 1px solid black;
    }
}
</style>