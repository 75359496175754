<template>
    <div>
        <div class="d-flex flex-row mb-5">
            <v-text-field 
                label="Макс. ширина изображения" 
                v-model="settings.maxWidth"
                :hint="!settings.maxWidth ? 'Изображение будет растянуто по ширине контейнера' : ''"
                persistent-hint
                type="number"
                class="maxW-150 mr-2"
            />
            <v-text-field 
                label="Толщина линии кисти" 
                v-model="settings.lineWidth"
                type="number"
                class="maxW-150 mr-2"
            />
        </div>
        <div class="d-flex flex-column mb-5">
            <v-label>Палитра цветов</v-label>
            <div class="d-flex flex-row mt-2">
                <div
                    v-for="(color, index) in settings.colors"
                    :key="index"
                    class="d-flex flex-row align-center mr-2"
                >
                    <v-chip
                        :color="color"
                        class="c-pointer"
                        @click="selectedColorIndex = index"
                    />
                    <v-icon small style="height: 15px; width: 15px" @click="removeColor(index)">mdi-close</v-icon>
                </div>
                <v-chip outlined @click="addColor">+ Цвет</v-chip>
            </div>
            <p v-if="!settings.colors || settings.colors.length === 0" class="text--secondary text-caption">Палитру не отрисовываем, кисть - черная.</p>
            <p v-else-if="settings.colors.length === 1" class="text--secondary text-caption">Палитру не отрисовываем. Используется цвет указанный выше.</p>
            <p v-else-if="settings.colors.length > 1" class="text--secondary text-caption">Для указанного набора цветов будет выведена палитра.</p>

            <v-dialog
                v-if="showColorPicker"
                :value="showColorPicker"
                max-width="500px"
                @click:outside="selectedColorIndex = null"
            >
                <v-card>
                    <v-toolbar
                        dark
                        color="primary"
                    >
                        <v-toolbar-title>Выбор цвета</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn
                            icon
                            dark
                            @click="selectedColorIndex = null"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <div class="d-flex justify-center w-100 pa-5">
                        <v-color-picker
                            :value="settings.colors[selectedColorIndex]"
                            @input="onColorPickerInput"
                        />
                    </div>
                </v-card>
            </v-dialog>
        </div>
        <template v-if="model">
            <div class="d-flex flex-column mb-5">
                <v-label>Изображение фона</v-label>
                <media-library
                    v-model="settings.backgroundImage.src"
                    :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
                    label="Изображение"
                    class="mt-2"
                />
            </div>
            <div class="d-flex flex-column mb-5">
                <v-label>Изображение ответа</v-label>
                <media-library
                    v-model="correctAnswer.src"
                    :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
                    label="Изображение"
                    class="mt-2"
                />
            </div>
        </template>
        <v-alert v-else type="error" class="my-2">
            Возможность использования медиа-библиотеки заблокирована для несохранённой формы.
            Сохраните форму и обновите страницу.
        </v-alert>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import MediaLibrary from '@/components/inputs/media-library'
import StringHelper from '@/plugins/string'

const defaultArrowColor = 'rgb(0, 0, 0)'

export default {
    components: { MediaLibrary },
    mixins: [interactiveMixin],
    inject: ['model'],
    data () {
        return {
            selectedColorIndex: null,
            correctAnswer: { src: null },
            settings: {
                maxWidth: 400, // null - растягивем по контейнеру
                colors: null, // null - палитру не отрисовываем, кисть - черная. И палитру не отрисовываем, если из 1 цвета палитра // ['#FEE063', 'red']
                lineWidth: 5, // Курсор можно отобразить кружком. Если будет кружок слишком маленьким, то задать ему какой минимальный диаметр
                backgroundImage: { src: null }
            }
        }
    },
    computed: {
        showColorPicker () {
            return typeof this.selectedColorIndex === 'number'
        },
        transliterate () { return StringHelper.transliterate }
    },
    methods: {
        addColor () {
            if (Array.isArray(this.settings.colors)) {
                this.settings.colors.push('#000000')
            } else {
                this.settings.colors = ['#000000']
            }
        },
        removeColor (colorIndex) {
            if (!Array.isArray(this.settings.colors)) { return }
            this.settings.colors.splice(colorIndex, 1)
        },
        onColorPickerInput (event) {
            if (this.selectedColorIndex === null) { return }
            this.settings.colors[this.selectedColorIndex] = this.transformColorToString(event)
        },
        transformColorToString (value) {
            let color = defaultArrowColor
            // Если в переменной объект, то преобразовываем в rgba строку
            if (typeof value === 'object') {
                const rgba = value.rgba
                color = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
            }
            // Если в переменной строка, то это подходящий вариант и мы присваиваем
            // это значение в итоговую переменную
            else if (typeof value === 'string') {
                color = value
            }
            // Иначе считаем что в value указано любое другое значение,
            // а значит мы оставляем значение defaultArrowColor
            return color
        }
    }
}
</script>