<template>
  <div>
        <v-card class="mt-2">
            <p class="text-h6 text--primary ml-4 mt-4 mb-0">Список членов семьи</p>
            <v-card-text
                v-for="(person, i) in correctAnswer"
                :key="person.cellNumber"
                class="d-flex"
            >
                <v-text-field
                    :value="i+1"
                    type="number"
                    label="Номер ячейки"
                    disabled
                    class="maxW-150 mr-2"
                ></v-text-field>

                <v-select
                    v-model="person.familyNumber"
                    :items="familyNumbers"
                    label="Номер семьи"
                    class="maxW-150 mr-2"
                />

                <v-text-field v-model="person.name" label="Имя" class="mr-2" />

                <v-select
                    v-model="person.relation"
                    :items="degreesOfKinship"
                    label="Степень родства"
                    class="maxW-200"
                >
                    <template v-slot:append-outer>
                        <v-icon v-if="correctAnswer.length > 1" @click="personRemove(i)">mdi-minus-circle-outline</v-icon>
                    </template>
                </v-select>
            </v-card-text>
            <v-icon class="ml-4 my-4" @click="personAdd">mdi-plus-circle-outline</v-icon>
        </v-card>

        <div v-if="warnings && warnings.length" class="d-flex flex-column mt-4">
            <v-alert
                v-for="(warning, index) in warnings"
                :key="index"
                dense
                icon="mdi-alert-outline"
                outlined
                type="warning"
            >{{ warning }}</v-alert>
        </div>
  </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'

export default {
    mixins: [interactiveMixin],
    data () {
        return {
            degreesOfKinship: [
                { text: 'Бабушка', value: 'Бабушка' },
                { text: 'Дедушка', value: 'Дедушка' },
                { text: 'Мама', value: 'Мама' },
                { text: 'Папа', value: 'Папа' },
                { text: 'Тётя', value: 'Тётя' },
                { text: 'Дядя', value: 'Дядя' },
                { text: 'Ребёнок', value: null }
            ],
            familyNumbers: [1, 2],
            correctAnswer: [],
            settings: { timestamp: Date.now() }
        }
    },
    computed: {
        warnings () {
            const warnings = []
            this.correctAnswer.forEach((item, index) => {
                if (
                    item.relation === 'Мама' &&
                        !(
                            this.correctAnswer[index + 1]?.relation === 'Папа' ||
                            this.correctAnswer[index - 1]?.relation === 'Папа'
                        )
                ) {
                    warnings.push('Рекомендация: Маму и папу ставить рядом друг с другом.')
                }
            })
            if (this.correctAnswer.filter(item => item.relation === 'Мама')?.length > 1) {
                warnings.push('Предупреждение: Мама в семье может быть только одна.')
            }
            if (this.correctAnswer.filter(item => item.relation === 'Папа')?.length > 1) {
                warnings.push('Предупреждение: Папа в семье может быть только один.')
            }
            return Array.from(new Set(warnings))
        }
    },
    mounted () {
        if (this.correctAnswer.length < 1) {
            this.personAdd()
        }
    },
    methods: {
        personAdd() {
            this.correctAnswer.push({
                cellNumber: this.correctAnswer.length + 1,
                familyNumber: 1,
                name: '',
                relation: null
            })
        },
        personRemove(index) {
            this.correctAnswer.splice(index, 1)
        },
        beforeUpdate () {
            this.correctAnswer.forEach(item => {
                // Принудительно устанавливаем детям семью номер 1. Важно для работы интерактива
                if (item.relation === null) { item.familyNumber = 1; }
            });
            return {
                correctAnswer: this.correctAnswer,
                settings: this.settings
            }
        },
    }
}
</script>