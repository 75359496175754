<template>
    <div>
        <v-text-field 
            label="Заголовок поля ввода" 
            v-model="settings.label"
            hint="Не обязательно"
            persistent-hint
        ></v-text-field>
        <div class="d-flex flex-row">
            <v-text-field 
                label="Заполнитель поля"
                v-model="settings.placeholder"
                hint="Текст, который исчезнет после ввода значения" 
                persistent-hint
                type="text"
                class="mr-4"
            ></v-text-field>

            <v-text-field 
                label="Ширина поля"
                v-model="settings.width"
                type="text"
            ></v-text-field>
        </div>

        <div class="mt-5">
            <v-card>
                <v-card-text>
                    <p class="text-h6 text--primary">Варианты ответа</p>
                    <v-textarea
                        v-for="(answer, i) in correctAnswer"
                        :key="i"
                        v-model="correctAnswer[i]"
                        rows="3"
                    >
                        <template v-slot:append-outer>
                            <v-icon v-if="correctAnswer.length > 1" @click="answerRemove(i)">mdi-minus-circle-outline</v-icon>
                        </template>
                    </v-textarea>
                    <v-icon @click="answerAdd">mdi-plus-circle-outline</v-icon>
                </v-card-text>
            </v-card>
        </div>

        <p class="text-h6 text--primary mt-5">Изображения к ответу</p>
        <template v-if="model">
            <div
                v-for="answerImage in settings.answerImages"
                :key="answerImage.id"
            >
                <div class="d-flex align-center justify-space-between">
                    <media-library
                        v-model="answerImage.src"
                        :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
                        label="Изображение"
                        class="mb-5"
                    />
                    <v-icon v-if="settings.answerImages.length > 1" @click="answerImageRemove(answerImage.id)">mdi-minus-circle-outline</v-icon>
                </div>
            </div>
        <v-icon @click="answerImageAdd">mdi-plus-circle-outline</v-icon>
            
        </template>
        <v-alert v-else type="error" class="my-2">
            Возможность использования медиа-библиотеки заблокирована для несохранённой формы.
            Сохраните форму и обновите страницу.
        </v-alert>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import MediaLibrary from '@/components/inputs/media-library'
import StringHelper from '@/plugins/string'

export default {
    components: { MediaLibrary },
    mixins: [interactiveMixin],
    inject: ['model'],
    data () {
        return {
            correctAnswer: [],
            settings: {
                label: '',
                width: '100%',
                placeholder: '',
                answerImages: [ { id: 0, src: null } ]
            }
        }
    },
    computed: {
        transliterate () { return StringHelper.transliterate }
    },
    methods: {
        answerImageAdd () {
            const largestId = this.settings.answerImages.map(item => item.id).reduce((prev, value) => Math.max(prev, value), 0)
            this.settings.answerImages.push({ id: largestId + 1, src: null })
        },
        answerImageRemove (id) {
            this.settings.answerImages = this.settings.answerImages.filter(item => item.id !== id)
        },
        answerAdd () {
            this.correctAnswer.push('')
        },
        answerRemove (index) {
            this.correctAnswer.splice(index, 1)
        }
    }
}
</script>