<template>
    <div>
        <v-alert
            class="mt-2"
            border="top"
            colored-border
            type="info"
            elevation="2"
        >
            Строки задаются с правильно расставленными знаками ^ вместо ударений.
            Знак ставится после ударной гласной.
        </v-alert>

        <v-checkbox
            v-model="settings.viewAsSentence"
            label="Отображать как предложение"
            @change="onCheckboxChange"
        ></v-checkbox>

        <v-card class="mb-3">
            <v-card-text>
                <v-text-field
                    v-for="(item, i) in correctAnswer"
                    :key="i"
                    v-model="correctAnswer[i]"
                >
                    <template v-if="!settings.viewAsSentence" v-slot:append-outer>
                        <v-icon @click="answerRemove(i)">mdi-minus-circle-outline</v-icon>
                    </template>
                </v-text-field>
                <v-icon v-if="!settings.viewAsSentence" @click="answerAdd">mdi-plus-circle-outline</v-icon>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'

export default {
    mixins: [interactiveMixin],
    data () {
        return {
            settings: {
                viewAsSentence: false
            },
            correctAnswer: []
        }
    },
    methods: {
        answerAdd() {
            this.correctAnswer.push('')
        },
        answerRemove(index) {
            this.correctAnswer.splice(index, 1)
        },
        onCheckboxChange () {
            this.correctAnswer = ['']
        }
    }
}
</script>