<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="800px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    outlined
                    x-small
                    v-bind="attrs"
                    v-on="on"
                >Инструкция</v-btn>
            </template>

            <v-card>
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-toolbar-title>Инструкция по настройке определения ответа по формуле</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div class="d-flex flex-column justify-center w-100 pa-5">
                    <p>В поле формулы можно использовать все классические матиматические символы вроде `+-*/`</p>
                    <p>x - Искомое число.</p>
                    <p>&gt; - Число слева больше, чем справа.</p>
                    <p>&lt; - Число слева меньше, чем справа.</p>
                    <p>&gt;= - Число слева больше либо равно, числу справа.</p>
                    <p>&lt;= - Число слева меньше либо равно, числу справа.</p>
                    <p>== - Знак равенства. Одинарный символ равенства приведет к ошибке.</p>
                    <p>! - Знак отрицания. Рекомендуется к использованию в паре со скобками. Инвертирует значение выражения внутри скобок. Например: '10 &gt; x' значит, что 10 больше искомого числа. А '!(10 &gt; x)' значит, что 10 не больше искомого числа. То есть '10 &lt;= x'.</p>
                    <p>% - Оператор остатка от деления. Возвращает число, которое осталось после деления левой части на правую. Например `10%3` равно 1, так как 9 это целая часть от деления, дробную отбрасываем и до 10ти остается единица.</p>
                    <p>&amp;&amp; - Знак 'И'. Одинарный амперсант приведет к ошибке.</p>
                    <p>|| - Знак 'Или'. Одинарный символ приведет к ошибке.</p>

                    <p>Пример выражение: `x&gt;15 &amp;&amp; !(x%15) &amp;&amp; (x%6)`</p>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data () {
        return {
            dialog: false
        }
    }
}
</script>