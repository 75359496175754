<template>
    <div class="d-flex align-center">
        <v-slider
            v-model="settings.maxTime"
            label="Максимальная длительность записи (сек)"
            max="600"
            min="1"
            thumb-label="always"
            class="mt-10"
        />

        <v-checkbox
            v-model="settings.isRepeatRecord"
            label="Возможность перезаписи"
            class="mt-7 ml-5"
        ></v-checkbox>
    </div>
</template>
<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'

export default {
    mixins: [interactiveMixin],
    data () {
        return {
            settings: {
                maxTime: 60,
                isRepeatRecord: false
            },
            correctAnswer: null
        }
    },
    methods: {
        answerAdd() {
            this.correctAnswer.push('')
        },
        answerRemove(index) {
            this.correctAnswer.splice(index, 1)
        },
        onCheckboxChange () {
            this.correctAnswer = ['']
        }
    }
}
</script>