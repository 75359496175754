<template>
    <v-card>
        <v-toolbar
            dark
            color="primary"
        >
            <v-toolbar-title>Пример выполнения задания</v-toolbar-title>

            <v-btn
                elevation="0"
                color="blue"
                class="ml-4"
                :disabled="!hasChanges"
                @click="save"
            >Сохранить</v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                dark
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-container fluid class="pa-4">
            <v-row>
                <v-col cols="12" class="d-flex flex-column">
                    <span class="mr-2">Инструменты:</span>
                    <tool-bar v-model="activeTool" />
                    <v-divider class="my-2" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="d-flex flex-wrap align-center">
                    <div class="d-flex flex-column mr-10">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            :width="canvasSize"
                            :height="canvasSize"
                            :viewBox="`0 0 ${canvasSize} ${canvasSize}`"
                            class="d-block mx-auto"
                            @pointermove="pointerMoveHandler"
                            @pointerup="pointerUpHandler"
                        >
                            <circle
                                v-if="type === 'circle'"
                                :cx="canvasSize / 2"
                                :cy="canvasSize / 2"
                                :r="figureSize / 2"
                                fill="white"
                                stroke="black"
                                stroke-width="2px"
                            />
                            <rect
                                v-if="type === 'square'"
                                :x="(canvasSize - figureSize) / 2"
                                :y="(canvasSize - figureSize) / 2"
                                :width="figureSize"
                                :height="figureSize"
                                fill="white"
                                stroke="black"
                                stroke-width="2px"
                            />

                            <!-- Hint point -->
                            <template v-if="hintPoint">
                                <circle
                                    v-if="['answerLine'].includes(activeTool)"
                                    :cx="hintPoint.x"
                                    :cy="hintPoint.y"
                                    r="3"
                                    fill="black"
                                    class="pointer-events-none"
                                />
                            </template>
                            <!-- Answer lines -->
                            <g>
                                <line
                                    v-for="(line, index) in localLines"
                                    :key="`answer_line_${index}`"
                                    :x1="line[0].x"
                                    :y1="line[0].y"
                                    :x2="line[1] ? line[1].x : hintPoint.x"
                                    :y2="line[1] ? line[1].y : hintPoint.y"
                                    stroke-width="3"
                                    stroke="orange"
                                    class="c-pointer"
                                    :class="{'pointer-events-none': activeTool !== 'answerEraser'}"
                                    @click.stop="removeAnswerLine(index)"
                                />
                            </g>
                        </svg>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import ToolBar from './ToolBar.vue'

export default {
    components: { ToolBar },
    props: {
        lines: { type: Array, default: () => ([]) },
        size: { type: [String, Number], default: '500' }, // figure size
        type: { type: String, default: null } // figure type
    },
    data () {
        return {
            canvasSize: 500,
            localLines: null,
            hasChanges: false,
            activeTool: null,
            hintPoint: null,
            lineDrawing: false,
        }
    },
    watch: {
        localLines: {
            handler () { this.hasChanges = true },
            deep: true
        }
    },
    computed: {
        figureSize () {
            if (typeof this.size === 'number') { return this.size }
            return this.size.includes("%") ? (this.canvasSize / 100) * parseInt(this.size) : parseInt(this.size);
        }
    },
    created () {
        this.hintPoint = { x: 0, y: 0 }
        this.localLines = _.cloneDeep(this.lines)
    },
    methods: {
        /**
         * Хендлер перемещения курсора
         * @param {PointerEvent} event Событие курсора
         */
        pointerMoveHandler (event) {
            this.hintPointMove(event)
        },
        /**
         * Хендлер "отжатия" кнопки курсора
         * @param {Any} payload Любой набор данных
        */
        pointerUpHandler (payload) {
            if (!this.activeTool) { return false }
            const funcPartName = this.activeTool.charAt(0).toUpperCase() + this.activeTool.slice(1)
            this[`add${funcPartName}`]?.({...this.hintPoint}, payload)
        },
        /**
         * Метод обработки перемещения элемента подсказки
         * @param {PointerEvent} event Событие курсора
         */
        hintPointMove (event) {
            this.hintPoint = { x: event.offsetX, y: event.offsetY }
        },
        /**
         * Метод удаляющий из переданного массива линии, в которых точка начала равна точке конца
         * @param {Array} arr Массив с линиями
         */
        removeZeroLines (arr) {
            return arr.filter((el) => !(el[0].x === el[1].x && el[0].y === el[1].y))
        },
        /**
         * Метод очищает переданный массив от дубликатов линиий, оставляя только уникальные
         * @param {Array} arr Массив с линиями
         */
        removeSameLines (arr) {
            const lines = []
            arr.forEach((line) => {
                const lineIndex = lines.findIndex((l) => (l[0]?.x === line[0]?.x &&
                                                        l[0]?.y === line[0]?.y &&
                                                        l[1]?.x === line[1]?.x &&
                                                        l[1]?.y === line[1]?.y) ||
                                                        (l[0]?.x === line[1]?.x &&
                                                        l[0]?.y === line[1]?.y &&
                                                        l[1]?.x === line[0]?.x &&
                                                        l[1]?.y === line[0]?.y)
                                )
                if (lineIndex < 0) {
                    lines.push(line)
                }
            })
            return lines
        },
        /**
         * Метод добавляет ещё один элемент (линию) в массив correctAnswer
         * @param {Object {x: Number, y: Number}} Координата точки. Либо первой в линии, либо завершающей
         */
        addAnswerLine ({x, y}) {
            this.lineDrawing = true
            const unfinishedLine = this.localLines.find((l) => l.length === 1)
            // Finish line
            if (unfinishedLine) {
                unfinishedLine.push({x, y})
                this.lineDrawing = false
                this.localLines = this.removeSameLines(this.localLines)
                this.localLines = this.removeZeroLines(this.localLines)
            } else {
            // Start new line
                this.localLines.push([{x, y}])
            }
        },
        /**
         * Метод удаляет элемент (линию) из массива correctAnswer по индесу группы и индексу линии
         * @param {Number} index Индекс линии
         */
        removeAnswerLine(index) {
            this.localLines.splice(index, 1)
        },
        /**
         * Метод закрывает диалоговое окно
         */
        closeDialog () {
            this.$emit('close', false)
        },
        /**
         * Метод сохранения. Обновляем данные пропсов.
         */
        save () {
            this.$emit('update:lines', _.cloneDeep(this.localLines))
            this.hasChanges = false
        }
    }
}
</script>