<template>
    <v-menu offset-y>

      <template v-slot:activator="{ on, attrs }">
        <v-chip
            label
            outlined
            v-bind="attrs"
            v-on="on"
        >
            <span>
                <slot />
            </span>
        </v-chip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="$emit('input', item[itemValue])"
        >
          <v-list-item-title>{{ item[itemTitle] }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: { type: String, default: null },
        items: { type: Array, required: true },
        itemValue: { type: String, default: 'value' },
        itemTitle: { type: String, default: 'title' }
    }
}
</script>