<template>
  <div>
        <v-label>Настройки холста</v-label>
        <div class="d-flex">
            <v-text-field
                v-model="settings.cellSize"
                type="number"
                label="Размер клетки (px)"
                class="maxW-150 mr-5"
            />

            <v-text-field
                v-model="settings.cellsPerWidth"
                type="number"
                label="Клеток по горизонтали"
                class="maxW-150 mr-5"
            />

            <v-text-field
                v-model="settings.cellsPerHeight"
                type="number"
                label="Клеток по вертикали"
                class="maxW-150 mr-5"
            />

            <v-text-field
                v-model="settings.perimeter"
                type="number"
                label="Искомый периметр"
                class="maxW-150 mr-5"
            />

            <v-text-field
                v-model="settings.rects"
                type="number"
                label="Кол-во квадратов необходимых для образования фигуры"
                disabled
                hint="Заблокированный параметр"
                persistent-hint
                class="maxW-150"
            />
        </div>

        <p>Укажите возможный вариант правильного ответа.<br>Нарисованная вами фигура будет показана по кнопке "Посмотреть ответ".</p>

        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!settings.perimeter || settings.perimeter < 4 || settings.cellsPerWidth < 3 || settings.cellsPerHeight < 3"
                >Разметить клетки</v-btn>
            </template>
            <selector
                v-for="answer, index in correctAnswer"
                :key="index"
                v-model="dialog"
                :active-cells="correctAnswer[index]"
                :cell-size="parseInt(settings.cellSize)"
                :cells-per-width="parseInt(settings.cellsPerWidth)"
                :cells-per-height="parseInt(settings.cellsPerHeight)"
                :rects-count="parseInt(settings.rects)"
                :perimeter="parseInt(settings.perimeter)"
                @save="onSelectorSave"
            />
        </v-dialog>
  </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import Selector from './Selector.vue'

export default {
    mixins: [interactiveMixin],
    components: { Selector },
    inject: ['model'],
    data () {
        return {
            correctAnswer: [[]],
            dialog: false,
            settings: {
                cellSize: 20, // px
                cellsPerWidth: 10,
                cellsPerHeight: 10,
                rects: 3,
                perimeter: null
            }
        }
    },
    mounted () {},
    methods: {
        onSelectorSave (cells) {
            this.correctAnswer[0] = cells
        },
        beforeUpdate () {
            ['cellSize', 'cellsPerWidth', 'cellsPerHeight', 'rects', 'perimeter'].forEach((key) => {
                this.settings[key] = parseInt(this.settings[key])
            })
            return {
                correctAnswer: this.correctAnswer,
                settings: this.settings
            }
        }
    }
}
</script>